import tableUtil from './tableUtil'
import { T1 } from './total'
// 正常头部 closed
const S1 = {
  init(params) {
    this.params = params
    this.styleSet = params.opts.styleSet || {}
    this.groups = params.groups
    // 开启对比
    this.prop = params.prop
    // 指标（同环，对比除外）
    this.m_prop = params.m_prop
    let result = (this.result = JSON.parse(JSON.stringify(params.result)))
    this.eventDic = params.eventDic

    this.allDimValues = params.allDimValues
    this.Total = params.Total
    return {
      thead: this.thead(),
      tbody: result.length === 0 ? [] : this.tbody(result),
      Total: this.Total,
      thead_orig: this.thead(this.styleSet.enableOccupation),
    }
  },
  thead(orig) {
    // 表头 （分组 + 指标label）
    // d2_clone.data.selectedGroups
    // d2_clone.data.resultFilter.columns
    let head = []
    // 分组label
    // {label:'商场名', prop:'xx_dim'}
    this.groups.forEach(item => {
      head.push({
        label: item.unit ? item.title + '(' + item.unit + ')' : item.title,
        prop: item.alias,
        isGroup: true,
      })
    })

    // 指标label
    this.prop.forEach(item => {
      // 对比率指标
      const isenableComparison = this.styleSet.enableComparison || this.styleSet.contrastratio
      if (item.usage === 'Rate') {
        // 开启对比率
        if (isenableComparison) {
          head.push({
            label: item.unit ? item.title + '(' + item.unit + ')' : item.title,
            prop: item.alias,
            clickableType: item.clickableType,
            fullAlias: item.fullAlias,
          })
        }
      } else {
        //如果对比率开启 且对比值关闭 不显示对比值
        if ((item.usage === 'Contrast' && isenableComparison && this.styleSet.comparisonValue) || !isenableComparison || item.usage !== 'Contrast') {
          head.push({
            label: item.unit ? item.title + '(' + item.unit + ')' : item.title,
            prop: orig ? item.alias + '_orig' : item.alias,
            displayType: item.displayType,
            clickableType: item.clickableType,
            fullAlias: item.fullAlias,
          })
        }
        if (orig) {
          // 占比率 跟着实际指标后面
          if (item.usage === 'Measure' && this.styleSet.enableOccupation) {
            head.push({
              label: '占比',
              prop: `${item.alias}_occ`,
            })
          }
        }
      }
    })
    return head
  },
  tbody(result) {
    // 合并同类
    result = this.calcRowSpan(result)

    // 添加总计
    if (this.styleSet.aggregation && this.styleSet.aggregation !== 'none') {
      let T = new T1(this.params)
      result.unshift(T.count())
    }

    this.transform()
    return result
  },
  /**
   * 循环 result 加工
   * @param {*} result
   */
  transform() {
    this.result.forEach(item => {
      // 插入行号
      if (this.styleSet.enableRownumber) {
        item.table_span &&
          item.table_span.unshift({
            rowspan: 1,
            colspan: 1,
          })
      }

      // 占比率
      if (this.styleSet.enableOccupation) {
        this.m_prop.forEach(p_item => {
          let k = p_item.alias
          item[`${k}_occ`] = tableUtil.countRate(item[k], this.Total[k].num)
        })
      }

      // 留存模型占比 9604 -> 9604(11.35%)
      if (this.styleSet.enableTableRate) {
        this.m_prop.forEach(p_item => {
          let k = p_item.alias
          let duration = k.replace('person_number_', '')
          if (duration !== 'all' && item[k] !== undefined) {
            let rate = item[`${duration}_rate`] || item[`rate_${duration}`]
            if (rate) {
              item[k] += `(${rate})`
            }
          }
        })
      }
      //开启占比
      if (this.styleSet.enableOccupation) {
        this.m_prop.forEach(p_item => {
          let k = p_item.alias
          let duration = k.replace('person_number_', '')
          if (duration !== 'all' && item[k] !== undefined) {
            let rate = item[`${duration}_occ`]
            if (rate) {
              item[k + '_orig'] = item[k]
              item[k] += `(${rate})`
            }
          }
        })
      }
    })
  },

  /*
   * 合并列计算
   * styleSet table选项
   * allDimValues 单条数据key分组
   */
  calcRowSpan(result) {
    // 开启总计,插入第一行，忽略起始行
    if (!this.styleSet.enableConflation) {
      return result
    }
    for (let idx = 0, glen = this.groups.length - 1; idx < glen; idx++) {
      let temp = ''
      for (let n = this.allDimValues.length, i = n - 1; i >= 0; i--) {
        let item = this.allDimValues[i]
        let name = tableUtil.getName(item.split('[|]'), idx)
        let resultCur = result[i]
        let resultNext = result[i + 1]
        resultCur.table_span = resultCur.table_span || []
        resultCur.table_span[idx] = resultCur.table_span[idx] || {}
        if (temp === name) {
          resultCur.table_span[idx].rowspan = resultNext.table_span[idx].rowspan + 1
          resultCur.table_span[idx].colspan = 1
          resultNext.table_span[idx] = {
            rowspan: 0,
            colspan: 0,
          }
        } else {
          temp = name
          resultCur.table_span[idx] = {
            rowspan: 1,
            colspan: 1,
          }
        }
      }
    }
    return result
  },
}
export default S1
