import tableUtil from './tableUtil'
import { T2 } from './total'

// 完全转置 full
const S2 = {
  init(params) {
    this.params = params
    this.styleSet = params.opts.styleSet
    this.groups = params.groups
    this.prop = params.prop
    let result = (this.result = JSON.parse(JSON.stringify(params.result)))
    this.eventDic = params.eventDic

    this.allDimValues = params.allDimValues
    this.Total = params.Total
    return {
      thead: this.thead(),
      tbody: result.length === 0 ? [] : this.tbody(result),
      tbody_orig: result.length === 0 ? [] : this.tbody(result, this.styleSet.enableOccupation),
    }
  },
  /*
   * opts table选项
   * 合并同类单元格在head中处理
   * allDimValues 单条数据key分组
   * data 加工总数据
   */
  thead() {
    let allDimValues = this.allDimValues
    // 无分组时，预设头部
    if (allDimValues.length === 0) {
      return [
        {
          label: '指标',
          prop: 'prop_first',
        },
        {
          label: '值',
          prop: 'prop_0',
        },
      ]
    }

    let temp = []
    for (let n = allDimValues.length, i = 0; i < n; i++) {
      let arr = allDimValues[i].split('[|]')
      let prevArr = (allDimValues[i - 1] && allDimValues[i - 1].split('[|]')) || []
      let j = 0
      while (j < arr.length) {
        let curName = tableUtil.getName(arr, j)
        let prevName = tableUtil.getName(prevArr, j)
        // 只有合并单元格和不同数据时才添加
        if (!this.styleSet.enableConflation || prevName !== curName) {
          this.append(temp, j, arr[j], i)
        }
        j++
      }
    }
    //新增一个‘其它’列表，标记该列人数|店铺数 不可点击
    //单指标且单分组的图形，把部分小于等于一定量的数据统一归入‘其它’分组中显示
    let rowindex = this.result.findIndex(x => x.other_alias)
    if (rowindex >= 0) {
      temp[rowindex].prop = 'prop_other'
    }
    // 添加第一分组
    temp.splice(0, 0, tableUtil.nestColoum(this.addFirstCol(), 'prop_first'))
    // 添加总计
    if (this.styleSet.aggregation && this.styleSet.aggregation !== 'none') {
      temp.splice(1, 0, { label: tableUtil.labelEnum[this.styleSet.aggregation], prop: 'prop_count' })
    }

    return temp
  },
  // 第一列（分组集合）
  addFirstCol() {
    let arr = []
    let temp = this.groups
    if (this.groups.length === 0) {
      temp = this.prop
    }
    temp.forEach(item => {
      arr.push(item.title)
    })
    return arr
  },
  /**
   * arr 嵌套{label, prop}
   * i 嵌套层级
   * name 头部label
   * idx 数组索引 作为 col prop
   */
  append(arr, i, name, idx) {
    let tar = arr
    let isLast = i === this.groups.length - 1
    let temp = {
      label: name,
    }
    // 最后一个分组加上prop属性 （根据element ui table 嵌套column 规则）
    if (isLast) {
      temp.prop = 'prop_' + idx
    }
    // 根据索引递归查找children
    while (i > 0) {
      tar[tar.length - 1].children = tar[tar.length - 1].children || []
      tar = tar[tar.length - 1].children
      i--
    }
    tar.push(temp)
  },
  /**
   *
   * @param {Array} result
   * @return [{prop_0:x, label:1}, {prop_1:x, label:2}]
   */
  tbody(result, orig) {
    // 指标label
    let temp = []
    let sum = this.params.Total
    /**
     * prop_first 第一分组列
     * prop_count 总计
     *
     * */
    this.prop.forEach(item => {
      let o = {
        prop_first: item.unit ? item.title + '(' + item.unit + ')' : item.title,
      }
      //人数，店铺数
      if (item.clickableType) {
        o['clickableType'] = item.clickableType
      }

      // 占比率
      let occ = {}
      let k = item.alias

      // 添加总计
      let T = new T2(this.params)
      let v = T.count()
      if (this.styleSet.aggregation !== 'none') {
        o['prop_count'] = v[k]
      }
      o['prop'] = k
      result.forEach((sub, idx) => {
        // 每列对应prop值
        let okey = sub.other_alias ? 'prop_other' : 'prop_' + idx
        o[okey] = sub[k]
        // 占比率
        if (this.styleSet.enableOccupation && !k.includes('Rate')) {
          const occval = tableUtil.countRate(sub[k], sum[k].num)
          // 总计列占比率
          o['prop_count'] = tableUtil.countRate(v[k], sum[k].num)
          occ[okey] = occval
          o[okey] = orig ? o[okey] : o[okey] + `(${occval})`
        }
      })

      // 对比率指标（开启对比率才添加）
      const isenableComparison = this.styleSet.enableComparison || this.styleSet.contrastratio
      if (item.usage === 'Rate') {
        // 开启对比率
        if (isenableComparison) {
          temp.push(o)
        }
      } else {
        // 添加当下指标
        if ((item.usage === 'Contrast' && isenableComparison && this.styleSet.comparisonValue) || !isenableComparison || item.usage !== 'Contrast') {
          temp.push(o)
        }
        // 占比率 跟着实际指标后面
        if (orig && item.usage === 'Measure' && this.styleSet.enableOccupation) {
          occ.prop_first = '占比'
          temp.push(occ)
        }
      }
    })
    return temp
  },
}
export default S2
