import { commonjs } from 'bi_common'
// 空值占位符
const placeholder = '-'

// 用于对比的分组名累计 (累计天津大悦城,未知,工作日 == 天津大悦城,未知,工作日)
function getName(arr, idx) {
  let s = ''
  let i = 0
  while (i <= idx) {
    s += arr[i]
    i++
  }
  return s
}
/**
 * 嵌套表头
 * ['商场名','城市','工作日/周末']
 * {"label":"商场名","children":[{"label":"城市","children":[{"label":"工作日/周末"}]}]}
 *
 */
function nestColoum(arr, dim) {
  let i = arr.length - 1
  let o = {}
  while (i >= 0) {
    let old = o
    let tmp = {}
    tmp.label = arr[i]
    tmp.isGroup = true
    if (i === arr.length - 1) {
      tmp.prop = dim
    } else {
      tmp.children = [old]
    }
    o = tmp
    i--
  }
  return o
}

/**
 * 循环result
 * 生成统计值
 * 生成 all， first， other 分组值
 * 合并生成’其它‘
 */
function getResultData(styleSet, result, columns) {
  let groups = columns.filter(item => {
    return item.usage === 'Group'
  })
  // 分组（含布尔值）
  let bool_groups = groups.filter(g => {
    return g.dataType === 'Boolean'
  })

  let prop = columns.filter(item => {
    return item.usage !== 'Group'
  })
  // 真正指标(排除对比)
  let m_prop = columns.filter(item => {
    return item.usage !== 'Group' && item.usage !== 'Rate'
  })

  let allDimValues = []
  // 第一分组
  let firstDimValues = new Set()
  // 从分组集合（除第一分组）
  // 第一分组A1下有3组数据（其他分组3），A2名下有4组数据（其他分组4条）, 累计去除重复项
  let otherDimValues = new Set()

  // 分组
  let Total = {}
  //生成"中位数"
  prop.forEach(p => {
    let values = result.map(x => {
      return x[p.alias]
    })
    values.sort((a, b) => {
      return a - b
    })
    const valLens = values.length
    const isEven = Number.isInteger(valLens / 2)
    const median = values.length > 0 && isEven ? (values[valLens / 2] + values[valLens / 2 - 1]) / 2 : values[(valLens - 1) / 2]
    Total[p.alias] = {
      num: 0,
      extLen: 0,
      allLen: 0,
      median: median,
    }
  })
  //合并生成’其它‘
  result = commonjs.compressRows(styleSet, {
    rows: result,
    columns: columns,
  })
  result.forEach(item => {
    // 布尔值转中文
    bool_groups.forEach(g => {
      if (item[g.alias] === true) {
        item[g.alias] = '是'
      } else if (item[g.alias] === false) {
        item[g.alias] = '否'
      }
    })
    // let k = (item.sys_gen_key = item.sys_gen_key.replace('False', '否').replace('True', '是'))
    // let arr = k.split(',')
    // 生成 sys_gen_key
    let sys_gen_key = []
    groups.forEach(j => {
      sys_gen_key.push(item[j.alias])
    })

    let k = (item.sys_gen_key = sys_gen_key.join('[|]'))
    let arr = k.split('[|]')
    k && allDimValues.push(k)
    firstDimValues.add(arr[0])
    otherDimValues.add(arr.slice(1).join('[|]'))
    prop.forEach(p => {
      if (p.usage === 'Rate') {
        return false
      }
      let key = p.alias
      Total[key]['allLen'] += 1
      // 除去0的个数统计
      if (item[key] === 0) {
        Total[key].extLen += 1
      }
      Total[key].num += Number(item[key])
    })
  })

  return {
    allDimValues,
    firstDimValues: [...firstDimValues],
    /**
     * A1-3组数据, A2-4组数据
     * 去除累计项 导致分组名分离，需要排序
     */
    otherDimValues: [...otherDimValues].sort(),
    Total,
    result,
    prop,
    groups,
    bool_groups,
    m_prop,
  }
}

/**
 *
 * 占比率
 * ((row[k] / Total[k.split('|')[1]].num )  * 100).toFixed(2) + '%'
 */

function countRate(v, all) {
  if (v === 0 || all === 0 || v === '-' || all === '-') {
    return placeholder
  }
  const result = ((v / all) * 100).toFixed(2) + '%'
  return result.includes('-') && result.includes('%') ? result : result
}

/**
 * toFixed （浮点数保留2位小数点, 整数忽略）
 */
function toFixed(v) {
  let isFloat = v !== parseInt(v)
  return isFloat ? parseFloat(v.toFixed(2)) : v
}

/**
 * 总计名称枚举
 */
const labelEnum = {
  sum: '总计',
  average: '平均值',
  median: '中位数',
  averageExt: '去零平均值',
}

export default {
  getName,
  nestColoum,
  getResultData,
  countRate,
  labelEnum,
  placeholder,
  toFixed,
}
