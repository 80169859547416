import comm from '@/script/chartjs/comm'
import store from '@/store'

let drows = {
  //线图柱图
  drowLineV2(_this, config, propSetprops) {
    let setting = config.setting
    let styleSet = config.styleSet
    let basicInfo = store.state.basicInfo
    if (!styleSet.enableLegendPosition) {
      styleSet.enableLegendPosition = 'centerOnBottom'
    }
    let labelcolor = basicInfo.project.theme == 0 ? '#444' : '#ddd'
    let bordercolor = basicInfo.project.theme == 0 ? '#E9ECEF' : '#555'
    let ishor = styleSet.direction ? styleSet.direction == 'horizontal' : styleSet.enableHorizontal
    let isHorizontal = ishor && config.isline == 'bar' ? true : false //是否横向
    let isSlider = styleSet.enableSlider
    let position = styleSet.enableLegendPosition
    const grid_left = isHorizontal
      ? styleSet.showXAxisTitle
        ? comm.getGrid_left(config) + 20
        : comm.getGrid_left(config)
      : styleSet.showYAxisTitle
      ? comm.getGrid_left(config) + 20
      : comm.getGrid_left(config)

    const grid_top = styleSet.showYAxisTitle && styleSet.yAxisUnit ? comm.getGrid_top(config) + 10 : comm.getGrid_top(config)
    const grid_right = styleSet.yAxisUnit && isHorizontal ? comm.getGrid_right(config, config.seriesdata) + 15 : comm.getGrid_right(config, config.seriesdata)
    const yAxisnameGap = position == 'centerOnLeft' ? comm.getGrid_left(config) - comm.getLegendMaxWidth(config.legend) : comm.getGrid_left(config)
    let xnameGap = styleSet.enableDateExtend && styleSet.enableDateExtend != 'none' ? 47 : 30
    let nameGapval = isHorizontal && isSlider ? yAxisnameGap - 30 : yAxisnameGap
    let option = {
      grid: {
        top: styleSet.yAxisUnit ? (isHorizontal ? grid_top : grid_top + 15) : grid_top,
        bottom: comm.getGrid_bottom(config),
        right: styleSet.contrastratio ? grid_right + 25 : grid_right,
        left: grid_left > 40 ? grid_left : 40,
      },
      dataZoom: {
        orient: isHorizontal ? 'vertical' : 'horizontal',
        type: 'slider',
        show: isSlider ? true : false,
        showDetail: false,
        handleIcon:
          'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '150%',
        borderColor: bordercolor,
        width: isHorizontal ? 10 : '',
        height: isHorizontal ? '' : 10,
        left: position == 'centerOnLeft' ? comm.getLegendMaxWidth(config.legend) + 10 : 10,
        right: isHorizontal ? 0 : position == 'centerOnRight' ? comm.getLegendMaxWidth(config.legend) + 10 : 10,
        top: isHorizontal ? 33 : '',
        bottom: isHorizontal ? 33 : styleSet.enableLegend && position == 'centerOnBottom' ? 33 : 10,
      },
      tooltip: {
        ...comm.axis_tooltip,
        axisPointer: {
          axis: isHorizontal ? 'y' : 'auto',
        },
        formatter: function(params) {
          const ispencent = styleSet.enablePencentage || styleSet.contrastratio
          const isenableStack = config.linetype === 'line' && styleSet.enableStack && !styleSet.enablePencentage
          return _this.$commonJs2.getTooltip(params, option.series, ispencent, isenableStack, config.propfmt, propSetprops, setting)
        },
      },
      legend: {
        show: styleSet.enableLegend,
        type: styleSet.enableLegendType ? styleSet.enableLegendType : 'scroll',
        data: config.legend,
        itemHeight: 14,
        textStyle: {
          color: labelcolor,
          fontSize: 12,
          lineHeight: 14,
        },
        pageIconColor: labelcolor,
        pageIconSize: [10, 10],
        pageTextStyle: {
          fontSize: 12,
          lineHeight: 12,
          color: labelcolor,
        },
        top: position == 'centerOnTop' ? 'top' : position == 'centerOnBottom' ? 'bottom' : 'middle',
        left: position == 'centerOnLeft' ? 'left' : position == 'centerOnRight' ? 'right' : 'center',
        orient: ['centerOnTop', 'centerOnBottom'].includes(position) ? 'horizontal' : 'vertical',
      },
      xAxis: [
        {
          show: typeof styleSet.showXAxis != 'undefined' ? styleSet.showXAxis : true,
          name: config.xAxisname || '',
          nameTextStyle: {
            color: labelcolor,
          },
          nameGap: xnameGap,
          nameLocation: 'center',
          type: isHorizontal ? 'value' : 'category',
          boundaryGap: ['0%', '5%'],
          data: isHorizontal ? '' : config.xAxisdata,
          axisLabel: {
            color: labelcolor,
            lineStyle: {
              color: bordercolor,
            },
            formatter: function(v) {
              if (isHorizontal) {
                return _this.$commonJs.processAxisunit(config.max, v, setting, !isHorizontal && styleSet.enablePencentage)
              } else {
                return _this.$commonJs.formatAxis(store.state.pageunit, config.max, v, isHorizontal && styleSet.enablePencentage)
              }
            },
            interval: styleSet.enableOptimizexAxis ? 'auto' : 0,
            // rotate:styleSet.enableOptimizexAxis?'':45,
          },
          axisLine: {
            show: isHorizontal ? styleSet.yAxisLine : true,
            lineStyle: {
              color: bordercolor,
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: ['allLine', 'verticalLine'].includes(styleSet.gridLine),
            lineStyle: {
              type: 'dotted',
              color: bordercolor,
            },
          },
        },
      ],
      yAxis: [
        {
          show: typeof styleSet.showYAxis != 'undefined' ? styleSet.showYAxis : true,
          name: config.yAxisname || '',
          nameTextStyle: {
            color: labelcolor,
            align: 'left',
            padding: [0, 0, 0, -28],
          },
          nameLocation: 'center',
          nameGap: nameGapval > 30 ? nameGapval : 30,
          type: isHorizontal ? 'category' : 'value',
          data: isHorizontal ? config.xAxisdata : '',
          axisLabel: {
            show: true,
            color: labelcolor,
            formatter: function(v) {
              return _this.$commonJs.processAxisunit(config.max, v, setting, !isHorizontal && styleSet.enablePencentage)
            },
          },
          axisLine: {
            show: styleSet.yAxisLine,
            lineStyle: {
              color: bordercolor,
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: ['allLine', 'horizontalLine'].includes(styleSet.gridLine),
            lineStyle: {
              type: 'dotted',
              color: bordercolor,
            },
          },
        },
        {
          show: styleSet.contrastratio,
          name: '',
          nameTextStyle: {
            color: labelcolor,
            align: 'left',
            padding: [0, 0, 0, -28],
          },
          nameLocation: 'center',
          nameGap: 30,
          type: 'value',
          data: '',
          axisLabel: {
            show: true,
            color: labelcolor,
            formatter: function(v) {
              return _this.$commonJs.formatDisplay2(v, 'PERCENTAGE_DIRECT')
            },
          },
          axisLine: {
            show: isHorizontal ? true : styleSet.yAxisLine,
            lineStyle: {
              color: bordercolor,
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: ['allLine', 'horizontalLine'].includes(styleSet.gridLine),
            lineStyle: {
              type: 'dotted',
              color: bordercolor,
            },
          },
        },
        {
          name: styleSet.yAxisUnit,
          nameLocation: isHorizontal ? 'start' : 'end',
          position: isHorizontal ? 'right' : 'left',
          nameGap: isHorizontal ? 0 : 15,
          nameTextStyle: {
            color: labelcolor,
            align: isHorizontal ? 'left' : 'right',
            padding: isHorizontal ? [-10, 0, 0, 5] : '',
          },
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
      ],
      series: config.seriesdata,
    }
    if (styleSet.showYAxis) {
      var num_pattern = /^(-?\d+)(\.\d+)?$/
      if (num_pattern.test(styleSet.areaYAxis[0])) option.yAxis[0].min = styleSet.areaYAxis[0]
      if (num_pattern.test(styleSet.areaYAxis[1])) option.yAxis[0].max = styleSet.areaYAxis[1] > styleSet.areaYAxis[0] ? styleSet.areaYAxis[1] : null
    }
    if (position) {
      switch (position) {
        case 'centerOnTop':
          option.legend.top = 0
          break
        case 'centerOnLeft':
          option.legend.left = 0
          break
        case 'centerOnRight':
          option.legend.right = 0
          break
        case 'centerOnBottom':
          option.legend.bottom = 0
          break
      }
    } else {
      option.legend.bottom = 0
    }
    return option
  },
  //饼图
  drowPieV2(_this, config) {
    let setting = config.setting
    let styleSet = config.styleSet
    let basicInfo = store.state.basicInfo
    if (!styleSet.enableLegendPosition) {
      styleSet.enableLegendPosition = 'centerOnBottom'
    }
    let labelcolor = basicInfo.project.theme == 0 ? '#444' : '#ddd'
    let position = styleSet.enableLegendPosition
    let option = {
      grid: {
        top: '20',
        right: '10',
        bottom: '10',
      },
      tooltip: {
        ...comm.item_tooltip,
        // formatter: '{b} : {c} ({d}%)',
        formatter: function(e) {
          // const number = _this.$commonJs.propnumformat(store.state.pageunit, e.data.value, styleSet.enablePencentage, config.displayType, config.propfmt)
          const number = _this.$commonJs.processnumunit(e.data.value, setting, config.displayType, config.propfmt)
          return (
            `${styleSet.enableLable ? e.data.name + ` : ` : ''}` +
            `${styleSet.showNumber ? number : ''}` +
            `${styleSet.enablePencentage ? (styleSet.showNumber ? ` (${e.percent}%)` : ` ${e.percent}%`) : ''}`
          )
        },
      },
      legend: {
        show: styleSet.enableLegend,
        type: styleSet.enableLegendType ? styleSet.enableLegendType : 'scroll',
        data: config.legend,
        bottom: '0',
        textStyle: {
          color: labelcolor,
        },
        pageIconColor: '#909399',
        pageIconSize: [10, 10],
        pageTextStyle: {
          lineHeight: '15',
          color: labelcolor,
        },
      },
      series: [
        {
          type: 'pie',
          center: ['50%', (styleSet.centerPosition ? styleSet.centerPosition : styleSet.enableLegend ? 45 : 50) + '%'],
          radius: ['ring', 'rose'].includes(styleSet.type) ? [styleSet.voidRatio * 0.6 + '%', '60%'] : ['0%', '60%'],
          roseType: ['rose'].includes(styleSet.type) ? styleSet.roseType : false,
          data: config.seriesdata,
          label: {
            show: styleSet.enableLable || styleSet.showNumber || styleSet.enablePencentage,
            formatter: function(e) {
              // const number = _this.$commonJs.propnumformat(store.state.pageunit, e.data.value, styleSet.enablePencentage, config.displayType, config.propfmt)
              const number = _this.$commonJs.processnumunit(e.data.value, setting, config.displayType, config.propfmt)
              return (
                `${styleSet.enableLable ? e.data.name + `\n` : ''}` +
                `${styleSet.showNumber ? number : ''}` +
                `${styleSet.enablePencentage ? (styleSet.showNumber ? `(${e.percent}%)` : `${e.percent}%`) : ''}`
              )
            },
            color: labelcolor,
          },
          labelLine: {
            show: styleSet.showGuideLine,
          },
          itemStyle: {
            // borderColor:basicInfo.project.theme==0?'#fff':'#333',
            borderColor: basicInfo.project.theme == 0 ? '#fff' : '#333',
            borderWidth: config.seriesdata.length > 1 ? 3 : 0,
            // borderWidth: 4,
          },
          emphasis: {
            itemStyle: {
              opacity: 0.9,
            },
          },
        },
      ],
    }
    if (position) {
      option.legend.top = position == 'centerOnTop' ? 'top' : position == 'centerOnBottom' ? 'bottom' : 'middle'
      option.legend.left = position == 'centerOnLeft' ? 'left' : position == 'centerOnRight' ? 'right' : 'center'
      option.legend.orient = ['centerOnTop', 'centerOnBottom'].includes(position) ? 'horizontal' : 'vertical'
    }
    return option
  },
  //漏斗
  drowFunnelV2(_this, config) {
    let setting = config.setting
    let styleSet = config.styleSet
    let basicInfo = store.state.basicInfo
    let labelcolor = basicInfo.project.theme == 0 ? '#444' : '#ddd'
    let position = styleSet.enableLegendPosition
    let option = {
      tooltip: {
        ...comm.item_tooltip,
      },
      legend: {
        show: styleSet.enableLegend,
        data: config.legend,
        orient: 'vertical',
        left: 'right',
        bottom: '30',
        textStyle: {
          color: labelcolor,
        },
        pageIconColor: '#909399',
        pageIconSize: [10, 10],
        pageTextStyle: {
          lineHeight: '15',
          color: labelcolor,
        },
      },
      series: config.seriesdata,
    }
    if (position) {
      option.legend.top = position == 'centerOnTop' ? 'top' : position == 'centerOnBottom' ? 'bottom' : 'middle'
      option.legend.left = position == 'centerOnLeft' ? 'left' : position == 'centerOnRight' ? 'right' : 'center'
      option.legend.orient = ['centerOnTop', 'centerOnBottom'].includes(position) ? 'horizontal' : 'vertical'
    }
    return option
  },
  //多轴线图柱图
  drowMultiaxialV2(_this, config, propSetprops) {
    let setting = config.setting
    let styleSet = config.styleSet
    let basicInfo = store.state.basicInfo
    if (!styleSet.enableLegendPosition) {
      styleSet.enableLegendPosition = 'centerOnBottom'
    }
    let labelcolor = basicInfo.project.theme == 0 ? '#444' : '#ddd'
    let bordercolor = basicInfo.project.theme == 0 ? '#E9ECEF' : '#555'
    const position = styleSet.enableLegendPosition
    let xnameGap = styleSet.enableDateExtend && styleSet.enableDateExtend != 'none' ? 42 : 25
    let rightlen = 0
    let leftlen = 0
    config.maxarrlen.forEach((el, i) => {
      i % 2 == 0 ? (leftlen += el + 15) : (rightlen += el + 15)
    })
    let option = {
      title: { text: '' },
      grid: {
        top: comm.getGrid_top(config),
        right: position == 'centerOnRight' ? rightlen + comm.getLegendMaxWidth(config.legend) : rightlen,
        left: position == 'centerOnLeft' ? leftlen + comm.getLegendMaxWidth(config.legend) : leftlen,
        bottom: comm.getGrid_bottom(config),
      },
      dataZoom: {
        type: 'slider',
        show: styleSet.enableSlider ? styleSet.enableSlider : false,
        showDetail: false,
        handleIcon:
          'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '150%',
        height: 10,
        borderColor: bordercolor,
        bottom: styleSet.enableLegend && position == 'centerOnBottom' ? 33 : 10,
      },
      tooltip: {
        ...comm.axis_tooltip,
        formatter: function(params) {
          return _this.$commonJs2.getTooltip(params, option.series, '', '', config.propfmt, propSetprops)
        },
      },
      legend: {
        show: styleSet.enableLegend,
        type: styleSet.enableLegendType,
        data: config.legend,
        textStyle: {
          color: labelcolor,
        },
        pageIconColor: '#909399',
        pageIconSize: [10, 10],
        pageTextStyle: {
          fontSize: 12,
          lineHeight: 12,
          color: labelcolor,
        },
        top: position == 'centerOnTop' ? 'top' : position == 'centerOnBottom' ? 'bottom' : 'middle',
        left: position == 'centerOnLeft' ? 'left' : position == 'centerOnRight' ? 'right' : 'center',
        orient: ['centerOnTop', 'centerOnBottom'].includes(position) ? 'horizontal' : 'vertical',
      },
      xAxis: {
        show: typeof styleSet.showXAxis != 'undefined' ? styleSet.showXAxis : true,
        type: 'category',
        name: config.xAxisname || '',
        nameTextStyle: {
          color: labelcolor,
        },
        nameLocation: 'center',
        nameGap: 25,
        data: config.xAxisdata,
        axisLabel: {
          show: true,
          color: labelcolor,
          interval: styleSet.enableOptimizexAxis ? 'auto' : 0,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: bordercolor,
          },
        },
        nameGap: xnameGap,
        axisTick: {
          show: false,
          // alignWithLabel: true,
          // lineStyle: {
          //   color: labelcolor,
          // },
        },
        splitLine: {
          show: ['allLine', 'verticalLine'].includes(styleSet.gridLine),
          lineStyle: {
            type: 'dotted',
            color: bordercolor,
          },
        },
      },
      yAxis: config.yAxis,
      series: config.seriesdata,
    }

    if (position) {
      switch (position) {
        case 'centerOnTop':
          option.legend.top = 0
          break
        case 'centerOnLeft':
          option.legend.left = 0
          break
        case 'centerOnRight':
          option.legend.right = 0
          break
        case 'centerOnBottom':
          option.legend.bottom = 0
          break
      }
    } else {
      option.legend.bottom = 0
    }
    return option
  },
  //散点图
  drowScatterV2(_this, config) {
    let setting = config.setting
    let styleSet = config.styleSet
    let basicInfo = store.state.basicInfo
    let labelcolor = basicInfo.project.theme == 0 ? '#444' : '#ddd'
    let bordercolor = basicInfo.project.theme == 0 ? '#E9ECEF' : '#555'
    const grid_top = styleSet.showYAxisTitle && styleSet.YAxisPosition == 'top' ? comm.getGrid_top(config) + 10 : comm.getGrid_top(config)
    let option = {
      title: { text: '' },
      grid: {
        top: styleSet.yAxisUnit ? grid_top + 15 : grid_top,
        right: comm.getGrid_right(config, config.seriesdata),
        left: styleSet.showYAxisTitle ? comm.getGrid_left(config) + 20 : comm.getGrid_left(config),
        bottom: comm.getGrid_bottom(config),
      },
      dataZoom: {
        type: 'slider',
        show: styleSet.enableSlider ? styleSet.enableSlider : false,
        showDetail: false,
        handleIcon:
          'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '150%',
        height: 10,
        borderColor: bordercolor,
        bottom: 10,
      },
      tooltip: {
        ...comm.item_tooltip,
        formatter: function(obj) {
          let html = ''
          html += obj.seriesName ? '<div style="font-size: 14px;margin-bottom: 5px;">' + obj.seriesName + '</div>' : ''
          option.series[0].columns.forEach((el, idx) => {
            let series = option.series[obj.seriesIndex]
            let dataval = series.columns.length == 2 ? series.data[0][idx] : obj.value[idx]
            html += '<div style="text-align:left;">' + el.title + ': ' + _this.$commonJs.formatDisplay(dataval, el.displayType) + '</div>'
          })
          return html
        },
      },
      xAxis: [
        {
          name: styleSet.showXAxisTitle ? config.xAxisname : '',
          show: typeof styleSet.showXAxis != 'undefined' ? styleSet.showXAxis : true,
          nameGap: 25,
          axisLabel: {
            color: labelcolor,
            formatter: function(v) {
              return _this.$commonJs.formatAxis(basicInfo.project.axisUnit, config.x_max, v)
            },
            interval: styleSet.enableOptimizexAxis ? 'auto' : 0,
            // rotate:styleSet.enableOptimizexAxis?'':45,
          },
          nameTextStyle: {
            color: labelcolor,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: bordercolor,
            },
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: bordercolor,
            },
          },
          splitLine: {
            show: ['allLine', 'verticalLine'].includes(styleSet.gridLine),
            lineStyle: {
              type: 'dotted',
              color: bordercolor,
            },
          },
          nameLocation: 'center',
        },
      ],
      yAxis: [
        {
          show: typeof styleSet.showYAxis != 'undefined' ? styleSet.showYAxis : true,
          name: styleSet.showYAxisTitle ? config.yAxisname : '',
          nameTextStyle: {
            color: labelcolor,
            align: 'left',
            padding: [0, 0, 0, -28],
          },
          nameLocation: 'center',
          nameGap: comm.getGrid_left(config),
          offset: 0,
          axisLabel: {
            color: labelcolor,
            formatter: function(v, obj) {
              return _this.$commonJs.processAxisunit(config.y_max, v, setting)
            },
          },
          axisLine: {
            show: styleSet.yAxisLine,
            lineStyle: {
              color: bordercolor,
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: ['allLine', 'horizontalLine'].includes(styleSet.gridLine),
            lineStyle: {
              type: 'dotted',
              color: bordercolor,
            },
          },
        },
        {
          name: styleSet.yAxisUnit,
          nameLocation: 'end',
          position: 'left',
          nameGap: 15,
          nameTextStyle: {
            color: labelcolor,
            align: 'right',
          },
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
      ],
      series: config.seriesdata,
    }

    if (styleSet.showYAxis) {
      var num_pattern = /^(-?\d+)(\.\d+)?$/
      if (num_pattern.test(styleSet.areaYAxis[0])) option.yAxis[0].min = styleSet.areaYAxis[0]
      if (num_pattern.test(styleSet.areaYAxis[1])) option.yAxis[0].max = styleSet.areaYAxis[1] > styleSet.areaYAxis[0] ? styleSet.areaYAxis[1] : null
    }
    return option
  },
  //关系图
  drowRelationV2(_this, config) {
    let setting = config.setting
    let styleSet = config.styleSet
    let basicInfo = store.state.basicInfo
    let option = {
      grid: {
        top: '20',
        right: '10',
      },
      tooltip: {
        ...comm.item_tooltip,
        formatter: function(params, ticket, callback) {
          //点击圆点触发
          if (params.data.id) {
            var tip = ''
            var row = ''
            for (var i = 0; i < params.data.lists.length; i++) {
              row = params.data.lists[i]
              if (row['g0'] == params.data.id) {
                tip += "<div style='padding:2px 0px 3px 5px;'>" + row['g1'] + ' : ' + row['c0'] + '&nbsp&nbsp' + '</div>'
              }
            }
            var txt = "<div style='padding:2px 0px 3px 5px;'>关联人数</div>"
            tip = tip == '' ? tip : txt + tip
            return tip
          }
        },
      },
      series: {
        type: 'graph',
        layout: 'force',
        edgeSymbol: ['', 'arrow'],
        force: {
          repulsion: 100,
          edgeLength: [20, 300],
        },
        roam: true,
        focusNodeAdjacency: true,
        animation: true,
        animationThreshold: 10,
        lineStyle: {
          show: true,
          normal: {
            width: 0.5,
            curveness: 0.3,
            opacity: 0.7,
          },
        },
        label: {
          position: 'right',
          show: styleSet.enableLable,
          color: basicInfo.project.theme == 0 ? '#444' : '#ddd',
        },
        data: config.datas,
        links: config.links,
      },
    }
    return option
  },
  //雷达图
  drowRadarV2(_this, config) {
    let setting = config.setting
    let styleSet = config.styleSet
    let basicInfo = store.state.basicInfo
    let labelcolor = basicInfo.project.theme == 0 ? '#444' : '#ddd'
    let option = {
      grid: {
        top: '20',
        right: '10',
      },
      tooltip: {
        ...comm.item_tooltip,
        formatter: function(obj) {
          let html = ''
          html += obj.name ? '<div style="font-size: 14px;padding:4px 5px;">' + obj.name + '</div>' : ''
          option.radar.indicator.forEach((el, idx) => {
            // const num = _this.$commonJs.propnumformat(store.state.pageunit, obj.value[idx], styleSet.enablePencentage, el.displayType, config.propfmt)
            const num = _this.$commonJs.processnumunit(obj.value[idx], setting, el.displayType, config.propfmt)
            html += '<div style="text-align:left;">' + el.name + ': ' + num + '</div>'
          })
          return html
        },
      },
      legend: {
        show: false,
        type: styleSet.enableLegendType,
        data: config.legend,
        bottom: '10',
        textStyle: {
          color: labelcolor,
        },
        pageIconColor: '#909399',
        pageIconSize: [10, 10],
        pageTextStyle: {
          lineHeight: '15',
          color: labelcolor,
        },
      },
      radar: {
        indicator: config.indicator,
        center: ['50%', '60%'],
        name: {
          textStyle: {
            color: labelcolor,
          },
        },
      },
      series: [
        {
          type: 'radar',
          data: config.seriesdata,
          label: {
            show: styleSet.enableLable,
            color: labelcolor,
            formatter: function(e) {
              // const number = _this.$commonJs.propnumformat(store.state.pageunit, e.value, styleSet.enablePencentage, config.displayType, config.propfmt)
              const number = _this.$commonJs.processnumunit(e.value, setting, config.displayType, config.propfmt)
              return number
            },
          },
        },
      ],
    }
    //雷达图 面积图
    if (styleSet.type == 'area') {
      option.series[0].areaStyle = {}
    }
    return option
  },

  //桑基图
  drowSankeyV2(_this, config) {
    let setting = config.setting
    let styleSet = config.styleSet
    let basicInfo = store.state.basicInfo
    let option = {
      tooltip: {
        ...comm.item_tooltip,
        triggerOn: 'mousemove',
        formatter: function(obj) {
          let html = ''
          if (obj.dataType === 'node' && config.dictionary[obj.name]) {
            html += obj.name ? '<div style="font-size: 14px;padding:4px 0;">' + obj.name + '(' + config.dictionary[obj.name].allnums + ')' + '</div>' : ''
            config.dictionary[obj.name].list.forEach(x => {
              html +=
                '<div style="text-align:left;">' +
                x.target +
                ': ' +
                x.value +
                '(' +
                ((x.value * 100) / config.dictionary[obj.name].allnums).toFixed(2) +
                '%)' +
                '</div>'
            })
          } else {
            if (config.dictionary[obj.data.source]) {
              html +=
                '<div style="text-align:left;">' +
                obj.data.source +
                ' ---> ' +
                obj.data.target +
                ': ' +
                obj.data.value +
                '(' +
                ((obj.data.value * 100) / config.dictionary[obj.data.source].allnums).toFixed(2) +
                '%)' +
                '</div>'
            } else {
              html += '<div style="text-align:left;">' + obj.name + '</div>'
            }
          }
          return html
        },
      },
      series: {
        type: 'sankey',
        right: 10,
        data: config.datas,
        links: config.links,
        lineStyle: {
          color: basicInfo.project.theme == 0 ? '#444' : '#ddd',
        },
        label: {
          show: styleSet.enableLable,
          color: basicInfo.project.theme == 0 ? '#444' : '#ddd',
        },
      },
    }
    return option
  },
  // 仪表盘
  drowGaugeV2(_this, config) {
    let setting = config.setting
    let styleSet = config.styleSet
    let basicInfo = store.state.basicInfo
    let option = {
      grid: {
        top: '20',
        right: '10',
      },
      tooltip: {
        ...comm.item_tooltip,
        formatter: '{b} : ' + ' {c}%',
      },
      series: config.seriesdata,
    }
    return option
  },

  //词云图
  drowWordCloud(_this, config) {
    let setting = config.setting
    let styleSet = config.styleSet

    let size = 72
    if (styleSet.numberFontSize != 'auto') {
      size = parseInt(styleSet.numberFontSize)
    }
    let option = {
      animation: false,
      layoutAnimation: false,
      tooltip: {
        ...comm.item_tooltip,
        // formatter: '{b} : {c}',
        formatter: function(e) {
          const number = _this.$commonJs.propnumformat(store.state.pageunit, e.data.value, styleSet.enablePencentage, config.displayType, config.propfmt)
          return e.data.name + ' : ' + number
        },
      },
      series: [
        {
          type: 'wordCloud',
          shape: 'circle',
          left: 'center',
          top: 'center',
          width: '70%',
          height: '80%',
          right: null,
          bottom: null,
          sizeRange: [12, size],
          rotationRange: styleSet.isRotation ? [-90, 90] : [0, 0],
          rotationStep: 45,
          gridSize: 20,
          drawOutOfBound: false,
          data: config.datas,
          animation: false,
          layoutAnimation: false,
        },
      ],
    }
    return option
  },

  //矩形树图
  drowTreemap(_this, config) {
    const setting = config.setting
    let styleSet = config.styleSet
    let option = {
      grid: {
        top: '20',
        bottom: '10',
        left: '10',
        right: '10',
      },
      tooltip: {
        ...comm.item_tooltip,
        // formatter: '{b} : {c}',
        formatter: function(e) {
          const number = _this.$commonJs.propnumformat(store.state.pageunit, e.data.value, styleSet.enablePencentage, config.displayType, config.propfmt)
          return (
            (styleSet.enableLable ? e.data.name + ' : ' : '') +
            (styleSet.showNumber ? number : '') +
            (styleSet.enablePencentage ? (styleSet.showNumber ? ` (${e.data.percent}%)` : ` ${e.data.percent}%`) : '')
          )
        },
      },
      series: {
        name: 'ALL',
        type: 'treemap',
        data: config.datas,
        levels: [
          {},
          {
            color: config.colors,
            colorMappingBy: 'index',
          },
        ],
        label: {
          normal: {
            show: true,
            formatter: function(e) {
              const number = _this.$commonJs.propnumformat(store.state.pageunit, e.data.value, styleSet.enablePencentage, config.displayType, config.propfmt)
              return (
                (styleSet.enableLable ? e.data.name + '\n\n' : '') +
                (styleSet.showNumber ? number : '') +
                (styleSet.enablePencentage ? (styleSet.showNumber ? ` (${e.data.percent}%)` : ` ${e.data.percent}%`) : '')
              )
            },
          },
        },
        breadcrumb: {
          show: false,
        },
      },
    }
    return option
  },
}

export default drows
