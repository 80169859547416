/**
 * aggregation 参数
 * sum 总计
 * average 平均值
 * median: 中位数
 * averageExt 去零平均值
 * none 不显示
 *
 * T1 close 正常列表
 * T2 full 完全转置
 * T3 part 部分转置
 */
import tableUtil from './tableUtil'

class Total {
  /**
   * @param {Object} params
   */
  constructor(params) {
    this.params = params
    this.styleSet = params.opts.styleSet || {}
    let groups = (this.groups = params.groups)
    // 开启对比
    this.prop = this.styleSet.enableComparison ? params.prop : params.m_prop
    // 指标（同环，对比除外）
    this.m_prop = params.m_prop
    this.result = params.result
    this.allDimValues = params.allDimValues
    // 第一分组key 兼容 group中 alias='xxx|1212'
    this.firstColCode = (groups[0] && groups[0].alias) || this.prop[0].alias
  }

  /**
   * 总计
   * 正常close 完全转置full 共用
   * {distinct_totalcount_15004: 100000,totalcount_15004: 100000}
   * */
  sumV1() {
    let temp = {}
    let aggregation = this.styleSet.aggregation
    let Total = this.params.Total
    this.prop.forEach(p => {
      // 对比率总计
      if (p.usage === 'Rate') {
        let idx = p.alias.lastIndexOf('_')
        // 原始指标名
        let alias1 = p.alias.slice(0, idx)
        // 对比指标名
        let alias2 = p.alias.replace('Rate', '')
        temp[p.alias] = tableUtil.countRate(Total[alias1].num - Total[alias2].num, Total[alias2].num)
        return false
      }
      let tempProp = Total[p.alias]
      let v = tempProp.num
      let extLen = tempProp.extLen
      let allLen = tempProp.allLen
      if (aggregation === 'average') {
        v = v / allLen
      } else if (aggregation === 'median') {
        v = tempProp.median
      } else if (aggregation === 'averageExt') {
        v = allLen == extLen ? '-' : v / (allLen - extLen)
      }
      let isFloat = v != '-' && v !== parseInt(v)
      temp[p.alias] = v != '-' ? Number(isFloat ? v.toFixed(2) : v) : v
    })
    return temp
  }
}

class T1 extends Total {
  constructor(params) {
    super(params)
  }
  /**
   * 总计合并列
   * 第1个分组 按分组个数合并列
   * 第2-n 设为0
   */
  colRow() {
    let cols_len = this.groups.length
    let table_span = []
    table_span.push({
      rowspan: 1,
      colspan: cols_len,
    })
    for (let i = 0; i < cols_len - 1; i++) {
      table_span.push({
        rowspan: 0,
        colspan: 0,
      })
    }
    return table_span
  }
  /**
   * return 单条数据
   * {label,合并行列,列对应的值}
   */
  count() {
    let aggregation = this.styleSet.aggregation
    return {
      [this.firstColCode]: tableUtil.labelEnum[aggregation],
      table_span: this.colRow(),
      // 值{ key: v}
      ...this.sumV1(),
      sum: true,
    }
  }
}

// 完全转置
class T2 extends Total {
  constructor(params) {
    super(params)
  }
  /**
   * return 单条数据
   * {label,合并行列,列对应的值}
   */
  count() {
    return {
      ...this.sumV1(),
    }
  }
}

export { T1, T2 }
