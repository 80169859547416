import commonJs from '@/script/common.js'
import store from '@/store'
let comm = {
  //计算图表grid距离
  getGrid_top(config) {
    let set = config.styleSet
    let grid_top = 30
    //图例在上面
    if (set.enableLegend && set.enableLegendPosition == 'centerOnTop') {
      grid_top += 20
    }
    return grid_top
  },
  getGrid_left(config) {
    let set = config.styleSet
    let grid_left = 20
    let position = set.enableLegend && set.enableLegendPosition
    let legend_w = comm.getLegendMaxWidth(config.legend)

    //是否横向
    if (set.direction && set.direction == 'horizontal') {
      //横向 超过200 获取前100条和后100最大宽度
      let maxLength = 0
      let xAxisdatas = config.xAxisdata
      let newdatas = []
      if (xAxisdatas.length > 200) {
        let before_datas = xAxisdatas.splice(0, 100)
        let after_datas = xAxisdatas.splice(xAxisdatas.length - 101, xAxisdatas.length - 1)
        newdatas = before_datas.concat(after_datas)
      } else {
        newdatas = xAxisdatas
      }
      newdatas.forEach(el => {
        let el_w = commonJs.getWordsWidth2(el)
        maxLength = el_w > maxLength ? el_w : maxLength
      })
      if (set.enableSlider) {
        maxLength += 25
      }
      if (set.showXAxisTitle) {
        maxLength += 20
      }
      if (position == 'centerOnLeft') {
        maxLength += legend_w + 5
      }
      grid_left = maxLength > 45 ? maxLength : 45
    } else {
      grid_left = config.max_w
      if (position == 'centerOnLeft') {
        grid_left += legend_w + 5
      }
    }

    return grid_left
  },
  getGrid_right(config, seriesdata) {
    let set = config.styleSet
    let grid_right = 20
    let position = set.enableLegend && set.enableLegendPosition
    let legend_w = comm.getLegendMaxWidth(config.legend)
    const markLine_idx = seriesdata[0] && seriesdata[0].type == 'scatter' ? 1 : 0
    //是否横向
    if (set.direction && set.direction == 'horizontal') {
    } else {
      let sublineVal = set.sublineVal || set.yAxisValue
      if (sublineVal) {
        let len = commonJs.getWordsWidth2(sublineVal)
        grid_right = len
      } else if (set.subline == 'measureField') {
        let num = seriesdata[0] && Number(seriesdata[0].markLine.data[markLine_idx].yAxis).toFixed(2)
        const formatnum = commonJs.formatnum(store.state.pageunit, num)
        let len = commonJs.getWordsWidth2(formatnum)
        grid_right = len
      } else if (set.subline == 'average') {
        let num = seriesdata[0] && Number(seriesdata[0].markLine.data[markLine_idx].yAxis).toFixed(2)
        const formatnum = commonJs.formatnum(store.state.pageunit, num)
        let len = commonJs.getWordsWidth2(formatnum)
        grid_right = len
      }
      if (position == 'centerOnRight') {
        grid_right += legend_w + 5
      }

      // 对比率 次Y轴
      if (set.contrastratio) {
        const serlist = seriesdata.find(x => x.yAxisIndex === 1)
        const maxnum = (serlist && _.max(serlist.data)) || ''
        grid_right = commonJs.getWordsWidth2(maxnum)
      }
    }

    return grid_right
  },
  getGrid_bottom(config) {
    let set = config.styleSet
    let grid_bottom = 25
    let position = set.enableLegend && set.enableLegendPosition
    if (position == 'centerOnBottom' && set.enableLegend) {
      grid_bottom += 25
    }
    if (set.direction == 'horizontal') {
      if (set.showYAxisTitle) {
        grid_bottom += 25
      }
    } else {
      if (set.enableSlider) {
        grid_bottom += 25
      }
      if (set.enableDateExtend && set.enableDateExtend != 'none') {
        if (set.enableSlider) {
          grid_bottom += 16
        } else {
          grid_bottom += 25
        }
      }
      if (set.showXAxisTitle) {
        grid_bottom += 25
      }
      if (set.enableDateExtend) {
        grid_bottom += 5
      }
    }
    return grid_bottom
  },
  // 计算图表 legend距离
  getLegend(config) {},
  //获取 legend 最长宽度
  getLegendMaxWidth(legends) {
    legends = legends || []
    let max_w = 0
    legends.forEach(x => {
      let leg_w = commonJs.getWordsWidth2(x)
      max_w = max_w > leg_w ? max_w : leg_w
    })
    return max_w + 15
  },

  // 坐标轴触发悬浮框, 主要在柱状图，折线图等会使用类目轴的图表中使用
  axis_tooltip: {
    trigger: 'axis',
    enterable: true,
    transitionDuration: 0.5,
    confine: true,
    padding: [3, 0, 3, 0],
    textStyle: {
      fontSize: 12,
      color: '#fff',
    },
    position: function(point) {
      return [point[0] + 3, point[1] + 3]
    },
    extraCssText: 'box-shadow: 0 0 8px 0 rgba(136,152,170,0.15);',
    backgroundColor: 'rgba(50,50,50,0.7)',
    borderColor: 'rgba(50,50,50,0.7)',
  },
  //数据项图形触发悬浮框, 主要在散点图，饼图等无类目轴的图表中使用
  item_tooltip: {
    trigger: 'item',
    enterable: true,
    transitionDuration: 0.5,
    confine: true,
    padding: 8,
    textStyle: {
      fontSize: 12,
      color: '#fff',
    },
    position: function(point) {
      return [point[0] + 3, point[1] + 3]
    },
    extraCssText: 'box-shadow: 0 0 8px 0 rgba(136,152,170,0.15);',
    backgroundColor: 'rgba(50,50,50,0.7)',
    borderColor: 'rgba(50,50,50,0.7)',
  },

  //根据数据条数得到一个颜色数组
  getArryColors(color, aLen) {
    let relacolors = []
    let chartColorV2 = JSON.parse(JSON.stringify(color))
    let colorLen = Math.ceil(aLen / chartColorV2.length)
    for (let i = 0; i <= colorLen; i++) {
      let newcolor = chartColorV2.map(x => {
        let c = {}
        c.color = x
        // c.opacity = (10 - i) / 10
        c.opacity = 1
        return c
      })
      relacolors = relacolors.concat(newcolor)
    }
    return relacolors
  },

  //线图面积图 渐变颜色
  getAreaStyle(_this, basicInfo, themeSet, serColor) {
    let area = {}
    let color1 = ''
    let color2 = ''
    color1 = comm.getRgba(serColor, 0)
    color2 = comm.getRgba(serColor, 0.4)
    area = new _this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
      {
        offset: 0,
        color: color1,
      },
      {
        offset: 1,
        color: color2,
      },
    ])

    return area
  },
  //十六进制颜色转为rgba
  getRgba(color, opcity) {
    //处理六位的颜色值
    var colorNew = []
    for (var i = 1; i < 7; i += 2) {
      colorNew.push(parseInt('0x' + color.slice(i, i + 2)))
    }
    return 'rgba(' + colorNew.join(',') + ',' + opcity + ')'
  },
}

export default comm
