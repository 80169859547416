import store from '@/store'
import _ from 'lodash'
import { Search2 } from '@/api/apiV2_dashboard'
import bi_echarts from 'mallcoo_bi_echarts'
const Table = bi_echarts.Table
import initMarkV2 from '@/script/bookmarkV2/initMarkV2'
import comJs from './com'
import linkageJS from './linkagejs'

let linkageDrillJS = {
  drillclick(_this, idx, clickobj, driidx = 0, closedrill) {
    const { drillFilter, chickparam } = clickobj
    let bookmark = _this.bookmarks[idx]
    let curbook = ''
    let el = _this.chartData[idx]
    const isEnabled = bookmark?.drillSetting?.isEnabled ?? false
    const bookmarkIDs = bookmark?.drillSetting?.bookmarkIDs ?? []
    if ((!isEnabled || bookmarkIDs.length == 0) && !closedrill) return
    // drillFilter 有值 是点击图表
    // driidx 有值 是点击tab
    // 如果点击图表 生成最新的 prefilter 和 tab

    const prefilterLen = el.prefilter && el.prefilter.length
    // 如果是最后一项
    const drillBookmarks = bookmark.drillBookmarks
    if (drillFilter && el.prefilter && drillBookmarks.length === prefilterLen) {
      return
    }

    if (drillFilter && !linkageDrillJS.issupportdrill(bookmark, el, prefilterLen)) return
    if (!linkageDrillJS.isCanDrill(drillFilter, el, bookmark)) {
      _this.$message(`未在钻取卡片找到之前所有分组条件！`)
      return
    }
    if (driidx == -1) {
      delete el.reqObj.injectedArguments.prefilter
      delete el.prefilter
      curbook = bookmark
    } else if (!drillFilter) {
      if (prefilterLen && prefilterLen > driidx) {
        el.prefilter.splice(driidx + 1, prefilterLen)
        el.clickparams.splice(driidx + 1, prefilterLen)
        let conditions = []
        el.prefilter.forEach(x => {
          conditions = conditions.concat(x.conditions)
        })
        const prefilter = {
          operator: 'and',
          conditions: conditions,
        }
        el.reqObj.injectedArguments.prefilter = prefilter
        curbook = bookmark.drillBookmarks[el.prefilter.length - 1]
      }
    }

    if (drillFilter) {
      // 如果 已有 prefilter 合并
      if (prefilterLen && prefilterLen > 0) {
        let conditions = []
        el.prefilter.forEach(x => {
          conditions = conditions.concat(x.conditions)
        })
        const prefilter = {
          operator: 'and',
          conditions: [...conditions, ...drillFilter.conditions],
        }
        el.reqObj.injectedArguments.prefilter = prefilter
        el.prefilter.push(drillFilter)
        el.clickparams.push(chickparam)
      } else {
        el.prefilter = []
        el.clickparams = []
        const prefilter = drillFilter
        el.reqObj.injectedArguments.prefilter = prefilter
        el.prefilter.push(drillFilter)
        el.clickparams.push(chickparam)
      }
      curbook = el.prefilter.length ? bookmark.drillBookmarks[el.prefilter.length - 1] : bookmark.drillBookmarks[0]
    }
    if (driidx >= 0) {
      driidx = el.prefilter.length - 1
    }
    // 初始卡片的时间传下去
    if (el.timeRange && !el.reqObj.injectedArguments.timeRange) {
      el.reqObj.injectedArguments.timeRange = el.timeRange
    }
    linkageDrillJS.searchchange(_this, idx, el.reqObj, '', '', curbook)
  },
  issupportdrill(bookmark, el, prefilterLen) {
    // 支持 (指标卡 柱图 线图 饼图) ['card','bar','line','pie']
    let isdrill = true
    let settings = ''
    if (prefilterLen) {
      const curbook = bookmark.drillBookmarks[el.prefilter.length - 1]
      settings = curbook.definition && JSON.parse(curbook.definition).settings
    } else {
      settings = JSON.parse(bookmark.definition).settings
    }
    if (settings && !['card', 'bar', 'line', 'pie'].includes(settings.type)) {
      isdrill = false
    }
    return isdrill
  },
  linkageclick(_this, idx, clickobj) {
    // 联动 每次点击 将当前卡片条件添加到 相关卡片linkages.filters:[]
    const { drillFilter, chickparam } = clickobj
    let bookmark = _this.bookmarks[idx]
    let setbookmarks = bookmark?.linkageSetting?.bookmarks ?? []
    setbookmarks.forEach(x => {
      const findbook = _this.bookmarks.find(book => book.id === x.id)
      const finddefinition = findbook && JSON.parse(findbook.definition)
      const finddeparameter = finddefinition?.parameter ?? null
      const copyfilter = drillFilter ? _.cloneDeep(drillFilter) : null
      if (drillFilter) {
        copyfilter.conditions = copyfilter.conditions.map(c => {
          c.code = x.linkageCode
          if (finddeparameter && finddeparameter.category) {
            c.category = finddeparameter.category
          }
          return c
        })
      }
      const newfilter = {
        id: bookmark.id,
        filter: copyfilter,
        chickparam,
      }
      let findbookmark = _this.bookmarks.find(b => b.id === x.id)
      if (!findbookmark) return
      let findfilteridx = findbookmark.linkages && findbookmark.linkages.filters && findbookmark.linkages.filters.findIndex(f => f.id === newfilter.id)
      findfilteridx = findfilteridx >= 0 ? findfilteridx : -1
      if (findfilteridx >= 0) {
        if (drillFilter) {
          _this.$set(findbookmark.linkages.filters, findfilteridx, newfilter)
        } else {
          const filters = findbookmark.linkages.filters.filter(f => f.id !== newfilter.id)
          const groups = findbookmark.linkages.groups.filter(f => f.id !== newfilter.id)
          _this.$set(findbookmark.linkages, 'filters', filters)
          _this.$set(findbookmark.linkages, 'groups', groups)
        }
      } else {
        findbookmark.linkages.filters.push(newfilter)
      }
    })
    setbookmarks.forEach((x, i) => {
      let el = _this.chartData.find(c => c.id == x.id)
      let findbookmark = _this.bookmarks.find(b => b.id === x.id)
      if (!findbookmark) return
      const addgroup = {
        id: bookmark.id,
        name: chickparam.name,
      }
      let findgroup = findbookmark.linkages && findbookmark.linkages.groups.findIndex(g => g.id === addgroup.id)
      if (findgroup >= 0 && drillFilter) {
        _this.$set(findbookmark.linkages.groups, findgroup, addgroup)
      } else if (drillFilter) {
        findbookmark.linkages.groups.push(addgroup)
      }
      // 被关联卡片如果没有当前分组属性 无效

      const groupData = linkageJS.getGroupData(findbookmark)
      // 所有属性
      let allproperties = []
      groupData.forEach(g => {
        g.child.forEach(c => {
          allproperties = allproperties.concat(c.properties)
        })
      })
      const findsamegroup = allproperties.find(p => p.code == x.linkageCode)
      if (!findsamegroup) {
        _this.$message(`未在联动卡片{${findbookmark.name}}找到当前分组属性！`)
        return
      }
      if (drillFilter) {
        const dataType = drillFilter?.conditions[0]?.dataType ?? ''
        if (findsamegroup.dataType !== dataType) {
          _this.$message(`卡片{${findbookmark.name}}关联字段类型与当前卡片分组不一致！`)
          return
        }
      }
      if (el.prefilter && el.prefilter.length > 0) {
        delete el.clickparams
        delete el.prefilter
        if (el.injectsearchparam && el.injectsearchparam.prefilter) {
          delete el.injectsearchparam.prefilter
          if (el.reqObj.injectedArguments && el.reqObj.injectedArguments.prefilter) {
            delete el.reqObj.injectedArguments.prefilter
          }
        }
      }
      linkageJS.searchchange(_this, el.index, el.reqObj, '', '', findbookmark)
    })
  },
  issupportlinkage(bookmark, el, prefilterLen) {
    // 支持 ( 柱图 线图 饼图) ['bar','line','pie']
    let isdrill = true
    let settings = ''
    if (prefilterLen) {
      const curbook = bookmark.drillBookmarks[el.prefilter.length - 1]
      settings = JSON.parse(curbook.definition).settings
    } else {
      settings = JSON.parse(bookmark.definition).settings
    }
    if (!['bar', 'line', 'pie'].includes(settings.type)) {
      isdrill = false
    }
    return isdrill
  },
  initLinkage(bookmarks, el) {
    // 当前卡片有联动
    if (el.linkageSetting && el.linkageSetting.isEnabled && el.linkageSetting.bookmarks.length > 0) {
      el.linkageSetting.bookmarks.forEach(x => {
        let findbookmark = bookmarks.find(b => b.id === x.id)
        if (findbookmark) {
          findbookmark.linkages.groups = []
        }
      })
    }
  },

  searchchange: (_this, idx, req, contrastitem, type, curbook) => {
    linkageDrillJS.searchchangeV2(_this, idx, req, contrastitem, type, curbook)
  },

  searchchangeV2(_this, idx, req, contrastitem, type, curbook) {
    _this.chartData[idx].canVisit = curbook.canVisit
    if (!curbook.canVisit) {
      return
    }
    let bookmark = curbook
    let definition = JSON.parse(bookmark.definition)
    let temp = ''
    let p = ''
    let p2 = {}
    temp = bookmark.category.slice(0, 1).toUpperCase() + bookmark.category.slice(1)
    p2 = definition
    _this.chartData[idx].loading = true
    _this.chartData[idx].option = null

    let inject = req.injectedArguments
    if (type == 'contrast' || type == 'Contrast') {
      let contrast = req.contrastType
      let v2item = {}
      v2item.v2time = inject && inject.timeRange ? inject.timeRange : req.parameter.timeRanges
      switch (contrast) {
        case 'nocontrast':
          contrast = 'Current'
          break
        case 'contrast':
          contrast = 'Contrast'
          var timeRa = inject && inject.timeRange ? inject.timeRange : req.parameter.timeRanges
          v2item.v2time = initMarkV2.getV2timeRan(_this, req, timeRa)
          break
        case 'last':
          contrast = 'Last'
          break
        case 'circle':
          contrast = 'Circle'
          break
        case 'lastAndCircle':
          contrast = 'LastAndCircle'
          break
      }
      let timeRan = _this.$commonJs.getContrastTimeV2(_this, req, v2item)
      inject.timeRange = timeRan
      inject.contrastType = contrast
      _this.chartData[idx].timeRange = timeRan
      p2.ContrastType = contrast
    } else if (type == 'slot') {
      inject.timeRange = JSON.parse(JSON.stringify(inject.timeRange || req.parameter.timeRanges))
      let contrast = inject.contrastType ? inject.contrastType : req.contrastType
      switch (contrast) {
        case 'nocontrast':
          contrast = 'Current'
          break
        case 'contrast':
          contrast = 'Contrast'
          break
        case 'last':
          contrast = 'Last'
          break
        case 'circle':
          contrast = 'Circle'
          break
        case 'lastAndCircle':
          contrast = 'LastAndCircle'
          break
      }
      inject.contrastType = contrast
      inject.selectedGroups = [{ code: 'eventTime', type: 'date', category: 3, value: contrastitem, text: '事件发生时间' }]
      p2.ContrastType = contrast
    } else if (type == 'mallid') {
      p.conditionGroup = JSON.parse(JSON.stringify(req.conditionGroup))
      inject.conditionGroup = JSON.parse(JSON.stringify(req.conditionGroup))
      inject.timeRange = JSON.parse(JSON.stringify(inject.timeRange || req.parameter.timeRanges))
    } else if (type == 'refresh') {
      if (inject.timeRange || req.parameter.timeRanges) {
        inject.timeRange = JSON.parse(JSON.stringify(inject.timeRange || req.parameter.timeRanges))
      }
    }
    if (inject && inject.timeRange) {
      req.timeRange = inject.timeRange
    }
    _this.$set(_this.chartData[idx], 'reqObj', req)
    if (inject.timeRange) {
      _this.$set(_this.chartData[idx], 'timeRange', inject.timeRange)
    }

    linkageDrillJS.getSearch2(_this, idx, temp, p2, 'search', req, type, curbook)
  },
  async getSearch2(_this, idx, temp, p, search, req, refresh, curbook) {
    let bookmark = _this.bookmarks[idx]
    let param = {
      id: (curbook && curbook.id) || (bookmark && bookmark.id),
    }
    if (search && refresh == 'refresh') {
      param.forceRefresh = true
      let time = []
      if (req.injectedArguments.timeRange) {
        time = req.injectedArguments.timeRange
      }
      if (temp == 'Template') {
        const components = p.parameter.components
        const tm = components.find(x => {
          return x.type == 'TimePicker'
        })
        if (tm) {
          time = req.timeRange
        }
      }
      if (time && time.length > 0) {
        param.timeRanges = time
      }
      if (req.injectedArguments.contrastType) {
        param.contrastType = req.injectedArguments.contrastType
      }
    }
    if (search && refresh != 'refresh') {
      param.contrastType = req.injectedArguments.contrastType
      if (p.contrastType != param.contrastType && param.contrastType != 'Current' && p.settings.type === 'table') {
        // p.settings.styleSet.comparisonValue = false
        p.settings.styleSet.enableComparison = true
      }
      if (req.injectedArguments.timeRange) {
        param.timeRanges = req.injectedArguments.timeRange
      }
      if (param.timeRanges && param.contrastType != 'Contrast') {
        param.timeRanges = [param.timeRanges[0]]
      }
      if (req.injectedArguments.selectedGroups) {
        param.groups = p.parameter.groups
        param.groups.forEach(g => {
          if (g.code == 'time' && g.mode == 'Normal') {
            g.preset = req.injectedArguments.selectedGroups[0].value
            g.ranges = null
          }
        })
      }
    }
    if (search) {
      // 过滤条件
      param.prefilter = req.injectedArguments.prefilter
      p.injectedArguments = req.injectedArguments
    }

    param = Object.assign(param)
    let hotmapdata = ['Behavior', 'behavior'].includes(temp) ? await initMarkV2.hotMap(_this, idx, p.parameter) : '1'

    // 如果有钻取
    function drillloop(_this, index) {
      const el = _this.bookmarks[index]
      if (el && el.drillSetting) {
        if (el.drillBookmarks && el.drillSetting.bookmarkIDs.length == el.drillBookmarks.length) {
          const drillParam = linkageDrillJS.initDrillParam(param, el)
          linkageDrillJS.rtsearch(_this, p, idx, temp, hotmapdata, drillParam)
        } else {
          setTimeout(async () => {
            return drillloop(_this, index)
          }, 100)
        }
      } else {
        linkageDrillJS.rtsearch(_this, p, idx, temp, hotmapdata, param)
      }
    }
    drillloop(_this, idx)
  },
  rtsearch(_this, p, idx, temp, hotmapdata, param) {
    return Search2(param)
      .then(async d => {
        _this.chartData[idx]['injectsearchparam'] = param
        //2.0 获取数据 直接通过筛选 拿到筛选数据
        // 如果是自定义分析 search 请求状态码是 201 继续请求 直到 200或者 返回错误
        if (temp == 'Advance') {
          let coderes = ''
          if (d && d.code == '201') {
            coderes = await getAdvanceSearch2(d.data.requestID)
            if (coderes.code == '200') {
              linkageDrillJS.searchEnd(_this, p, coderes, idx, temp, hotmapdata)
            }
          } else if (d.code == '200') {
            linkageDrillJS.searchEnd(_this, p, d, idx, temp, hotmapdata)
          }
          if (coderes && coderes.code == '202') {
            let searchRes = ''
            searchRes = await getAdvanceSearch2(d.data.requestID)
            if (searchRes && searchRes.code == '202') {
              let searchtimes = 1
              linkageDrillJS.recurrence(_this, searchRes, d, p, idx, temp, hotmapdata, searchtimes)
            } else if (searchRes.code == '200') {
              d = searchRes
              linkageDrillJS.searchEnd(_this, p, d, idx, temp, hotmapdata)
            }
          } else if (coderes && coderes.code == '200') {
            d = coderes
            linkageDrillJS.searchEnd(_this, p, d, idx, temp, hotmapdata)
          }
        } else {
          linkageDrillJS.searchEnd(_this, p, d, idx, temp, hotmapdata)
        }
      })
      .catch(err => {
        console.error(err)
        if (_this.chartData[idx]) _this.chartData[idx].loading = false
      })
  },
  searchEnd(_this, p, d, idx, temp, hotmapdata) {
    let actualLength = d.data.rows.length
    d.data.rows = d.data.rows.slice(0, 10000)
    let result = linkageDrillJS.getFilterData2(d.data, _this.chartData[idx], p.settings)
    result.actualLength = actualLength
    if (_this.chartData[idx]) {
      let option = null
      if (result.columns.length == 0 || result.rows.length == 0) {
        option = { noData: true }
      } else {
        let opts = {
          ...p.settings,
          analysisType: temp,
        }
        let tableObj = new Table({
          opts,
          columns: result.columns,
          result: result.rows,
        })
        //Table 内部修改了rows 需要同步更新d1
        result.rows = tableObj.params.result
        const tableObjdata = tableObj.init()
        option = {}
        option.noData = false
        _this.copyChartData[idx]['data'] = result
        _this.chartData[idx]['data'] = result
        _this.chartData[idx]['orginResult'] = result
        _this.copyChartData[idx]['mode'] = opts.type
        _this.copyChartData[idx]['styleset'] = p
        _this.copyChartData[idx]['tableObj'] = tableObj
        _this.copyChartData[idx]['inittableObj'] = tableObjdata
        _this.copyChartData[idx]['orginResult'] = result
        _this.chartData[idx]['tableObj'] = tableObj
        _this.chartData[idx]['inittableObj'] = tableObjdata
        let excelsObj = initMarkV2.getExcelData(tableObjdata)
        _this.copyChartData[idx]['excelsObj'] = excelsObj
        _this.chartData[idx]['excelsObj'] = excelsObj
        _this.chartData[idx]['resizedidx'] = 0

        _this.chartData[idx]['hotmapdata'] = hotmapdata

        // 如果没有名称设置 增加默认名称设置
        // 兼容早期卡片 没有 propSet ，从原始 columns 和 filterset 获取 分组和指标
        const propsets = p.settings.propSet
        if (!propsets && result.columns.length > 0 && p.settings.type !== 'table_more' && p.settings.type !== 'funnel') {
          const groupColumns = result.columns.filter(x => x.usage === 'Group')
          const respropColumns = result.columns.filter(x => x.usage === 'Measure')
          if (!p.settings.filterSet.columns) {
            p.settings.filterSet.columns = []
          }
          const filterprops = p.settings.filterSet.columns.filter(x => !x.usage || x.usage === 'Measure')
          const groups = groupColumns.map(x => {
            return {
              alias: x.alias,
              fullAlias: x.fullAlias,
              title: x.title,
            }
          })
          const props = filterprops.map(x => {
            const targ = respropColumns.find(t => t.fullAlias == x.fullAlias)
            const displayType = (targ && (targ.displayType || targ.dataType)) || ''
            const format = {
              thousands: true, // 千分位
              percent: targ && ['PERCENTAGE', 'PERCENTAGE_DIRECT', 'PERCENTAGE_INTERGER'].includes(displayType), // 百分比
              // 小数点位数,null 不做处理，0，1，2，3 是保留几位小数
              digit: ['PERCENTAGE', 'PERCENTAGE_DIRECT'].includes(displayType) ? 2 : displayType == 'PERCENTAGE_INTERGER' ? 0 : null,
            }
            return {
              alias: x.alias,
              fullAlias: x.fullAlias,
              title: x.name,
              format,
            }
          })

          const propsets = {
            groups,
            props,
          }
          p.settings.propSet = propsets
        }
        // 如果图表没有Y轴样式设置 增加默认设置
        if (['line', 'bar', 'bar_line', 'scatter', 'bubble', 'biaxial_graph'].includes(p.settings.type) && !p.settings.styleSet.yAxisformat) {
          p.settings.styleSet.yAxisNumUnit = 'none'
          p.settings.styleSet.yAxisformat = { thousands: true, percent: false, digit: null }
        }
        _this.chartData[idx]['settings'] = p.settings
        const oldcardinfo = _this.chartData[idx] && _this.chartData[idx]['cardinfos']
        let cardinfos = {
          index: idx,
          cardkey: oldcardinfo ? oldcardinfo.cardkey + idx : String(idx),
          cardresizekey: oldcardinfo ? oldcardinfo.cardkey + idx : String(idx),
          parameter: p.parameter,
          injectedArguments: p.injectedArguments,
          settings: p.settings,
          result,
          hotmapdata,
          projectopts: {
            basicInfo: _this.basicInfo,
            theme: _this.basicInfo.project.theme,
            mainColor: _this.basicInfo.project.mainColor,
            pageunit: _this.basicInfo.project.axisUnit,
            contrastTrendColor: _this.basicInfo.project.contrastTrendColor,
            projectMalls: _this.projectMalls,
            weathers: store.state.weathers,
            festivals: store.state.festivals,
          },
          cardopts: {
            searchCode: _this.chartData[idx].searchCode,
            category: temp,
          },
          issupportdrill: _this.chartData[idx].prefilter && _this.chartData[idx].prefilter.length > 0,
        }
        if (temp.toLowerCase() == 'segment') {
          cardinfos.cardopts.uniqueID = result.uniqueID
          cardinfos.cardopts.segmentName = p.settings.nameCN || p.settings.nameEN
        }
        _this.$set(_this.chartData[idx], 'cardinfos', cardinfos)

        // if (opts.type === 'card' && option && option.arr.length > 0) {
        //   _this.$set(_this.chartData[idx], 'curcard', option.arr[0])
        //   _this.$set(_this.chartData[idx], 'curcardlabel', option.arr[0].label)
        // }
      }
      _this.resetChartOption(idx, option)
      _this.chartData[idx].loading = false
    }
  },
  getFilterData2: (d, chart, settings) => {
    let filterSet = settings.filterSet
    let funnelarr = ['funnel', 'table_more']
    if (funnelarr.includes(settings.type)) {
      return d
    }

    let groups = d.columns.filter(x => {
      return x.usage == 'Group'
    })
    let measures = d.columns.filter(x => {
      return x.usage == 'Measure'
    })
    let rows = d.rows.filter(x => {
      let s = ''
      let isUnknown = false
      groups.forEach((g, gidx) => {
        //隐藏未知
        if (filterSet && filterSet.hideUnknown) {
          if (x[g.alias] == '-') {
            isUnknown = true
          }
        }
        if (gidx < groups.length - 1 && groups.length > 1) {
          s += x[g.alias] + '[|]'
        } else {
          s += x[g.alias]
        }
      })
      x.sys_gen_key = s
      // 指标类型 PERCENTAGE_DIRECT 的值 *0.01
      if (!x.per_direct) {
        d.columns.forEach(m => {
          if (m.displayType == 'PERCENTAGE_DIRECT') {
            x[m.alias] = x[m.alias] * 0.01
          }
        })
        x.per_direct = true
      }
      return x && !isUnknown
    })
    let filterdata = {}

    if (d.columns.length > 0) {
      const propsetGroup = settings?.propSet?.groups ?? []
      let oldname = ''
      filterdata.columns = d.columns.filter(x => {
        if (x.usage === 'Group') {
          const grp = propsetGroup.find(g => g.fullAlias === x.fullAlias)
          if (grp) x.title = grp.title
        }
        let targ =
          filterSet.columns &&
          filterSet.columns.find(t => {
            if (t.fullAlias == x.fullAlias) {
              oldname = x.title
            }
            return t.fullAlias == x.fullAlias
          })
        let isshow = targ && (!targ.usage || targ.usage === 'Measure') ? targ.selected : true
        x.title = targ && (!targ.usage || targ.usage === 'Measure') ? targ.name : initMarkV2.reviseName(d, x)
        if (!targ) {
          let meatarg =
            filterSet &&
            filterSet.columns &&
            filterSet.columns.find(t => {
              return x.alias.includes(t.alias)
            })
          if (meatarg) {
            x.title = x.title.replace(oldname, meatarg.name)
          }
        }
        return isshow
      })

      //指标数值过滤开启时，根据指标start，end过滤数值
      if (filterSet.filterValue) {
        rows = rows.filter(x => {
          let istrue = true
          filterSet.columns &&
            filterSet.columns.forEach(c => {
              let val =
                c.filterCode && x[c.filterCode]
                  ? typeof x[c.filterCode] === 'number'
                    ? x[c.filterCode]
                    : x[c.filterCode] == '-'
                    ? '-'
                    : parseFloat(x[c.filterCode].replace(/[,]/g, ''))
                  : x[c.alias]
              if ((c.start || c.start === 0) && (c.end || c.end === 0) && c.start <= c.end && val >= c.start && val <= c.end) {
              } else if ((c.start || c.start === 0) && (c.end || c.end === 0) && c.start > c.end && val >= c.start) {
              } else if (!c.start && c.start !== 0 && (c.end || c.end === 0) && val <= c.end) {
              } else if ((c.start || c.start === 0) && !c.end && c.end !== 0 && val >= c.start) {
              } else if (!c.start && c.start !== 0 && !c.end && c.end !== 0) {
              } else {
                istrue = false
              }
            })
          return istrue
        })
        filterdata.rows = rows
      } else {
        filterdata.rows = rows
      }
    } else {
      filterdata = d
      filterdata.rows = rows
    }
    if (d.timeRanges) {
      filterdata.timeRanges = d.timeRanges
    }
    if (d.uniqueID) {
      filterdata.uniqueID = d.uniqueID
    }
    return filterdata
  },
  initDrillParam(param, el) {
    let newparam = param
    // 如果有筛选条件
    if (param.prefilter) {
    } else {
      // param.prefilter = {}
    }
    return newparam
  },
  initgroupname(_this, idx, driidx) {
    let name = ''
    let chart = _this.chartData[idx]
    if (driidx == -1) {
      const parameter = chart.reqObj.parameter
      const settings = chart.reqObj.settings
      // 指标卡 无分组
      if (parameter.groups && parameter.groups.length == 0) {
        name = '卡片' || chart.reqObj.title
      } else {
        name = '卡片' || linkageDrillJS.initGroupName(parameter, settings, _this.bookmarks[idx])
      }
    } else {
      const bookmark = _this.bookmarks[idx].drillBookmarks[driidx]
      const clickparams = chart.clickparams[driidx]
      name = clickparams.name
      if (clickparams.type === 'card' && !clickparams.name && driidx == 0) {
        const def = JSON.parse(bookmark.definition)
        name = linkageDrillJS.initGroupName(def.parameter, def.settings, bookmark)
      } else {
        if (!clickparams.name) {
          const def = JSON.parse(bookmark.definition)
          if (def.parameter && def.parameter.groups && def.parameter.groups.length > 0) {
            name = linkageDrillJS.initGroupName(def.parameter, def.settings, bookmark)
          } else {
            name = bookmark.nameCN || bookmark.name
          }
        }
      }
    }
    return name
  },
  getGroupName(g, group, prefilter) {
    let name = ''
    name = group.name
    return name
  },
  initGroupName(parameter, settings, bookmark) {
    const behaviorMeta = store.state.behaviorMeta
    const attributeMeta = store.state.attributeMeta
    let name = ''
    // 事件
    if (parameter.events) {
      const eventids = parameter.events.map(x => x.id)
      let allevts = []
      behaviorMeta.forEach(x => {
        allevts = allevts.concat(x.events)
      })
      const events = allevts.filter(x => eventids.includes(x.id))
      let evtproperty = []
      events.forEach(x => {
        x.propertyGroups.forEach(g => {
          evtproperty = evtproperty.concat(g.properties)
        })
      })
      const propSet = settings.propSet
      const groups = propSet.groups
      parameter.groups.forEach(x => {
        const grp = evtproperty.find(e => e.code === x.code)
        const findPropGroup = groups.find(g => g.fullAlias.includes('_' + grp.code + '_'))
        const initname = findPropGroup ? findPropGroup.title : grp.name
        name += initname + ','
      })
    }
    // 属性
    else if (parameter.category) {
      const attrevt = attributeMeta.find(x => x.code === parameter.category)
      let evtproperty = attrevt.entities
      parameter.groups.forEach(x => {
        const grp = evtproperty.find(e => e.code === x.code)
        const initname = grp.name
        name += initname + ','
      })
    }
    name = name.slice(0, name.length - 1)
    return name || bookmark.nameCN || bookmark.name
  },
  initgroupname2(_this, idx, driidx) {
    let chart = _this.chartData[idx]
    let bookmark = _this.bookmarks[idx]
    let name = ''
    const behaviorMeta = store.state.behaviorMeta
    const attributeMeta = store.state.attributeMeta
    let parameter = ''
    if (driidx == -1) {
      parameter = chart.reqObj.parameter
      // 事件
      if (parameter.events) {
        const eventids = parameter.events.map(x => x.id)
        let allevts = []
        behaviorMeta.forEach(x => {
          allevts = allevts.concat(x.events)
        })
        const events = allevts.filter(x => eventids.includes(x.id))
        let evtproperty = []
        events.forEach(x => {
          x.propertyGroups.forEach(g => {
            evtproperty = evtproperty.concat(g.properties)
          })
        })
        parameter.groups.forEach(x => {
          const grp = evtproperty.find(e => e.code === x.code)
          const initname = linkageDrillJS.getGroupName(x, grp, chart.prefilter[0])
          name += initname + ','
        })
      }
      // 属性
      if (parameter.category) {
        const attrevt = attributeMeta.find(x => x.code === parameter.category)
        let evtproperty = attrevt.entities
        parameter.groups.forEach(x => {
          const grp = evtproperty.find(e => e.code === x.code)
          const initname = linkageDrillJS.getGroupName(x, grp, chart.prefilter[0])
          name += initname + ','
        })
      }
      name = name.slice(0, name.length - 1)
    } else {
      const book = bookmark.drillBookmarks[driidx]
      parameter = JSON.parse(book.definition).parameter
      const drillFilter = chart.prefilter[driidx]
      // 事件
      if (parameter.events) {
        const eventids = parameter.events.map(x => x.id)
        let allevts = []
        behaviorMeta.forEach(x => {
          allevts = allevts.concat(x.events)
        })
        const events = allevts.filter(x => eventids.includes(x.id))
        let evtproperty = []
        events.forEach(x => {
          x.propertyGroups.forEach(g => {
            evtproperty = evtproperty.concat(g.properties)
          })
        })
        if (drillFilter) {
          const groups = parameter.groups
          groups.forEach(x => {
            const grp = evtproperty.find(e => e.code === x.code)
            const initname = linkageDrillJS.getGroupName(x, grp, drillFilter)
            name += initname + ','
          })
        } else {
          const groups = parameter.groups
          if (groups) {
            groups.forEach(x => {
              const grp = evtproperty.find(e => e.code === x.code)
              const initname = linkageDrillJS.getGroupName(x, grp, drillFilter)
              name += initname + ','
            })
          }
        }
      }
      // 属性
      if (parameter.category) {
        const attrevt = attributeMeta.find(x => x.code === parameter.category)
        let evtproperty = attrevt.entities
        const groups = parameter.groups
        if (groups) {
          groups.forEach(x => {
            const grp = evtproperty.find(e => e.code === x.code)
            const initname = linkageDrillJS.getGroupName(x, grp, chart.prefilter[driidx])
            name += initname + ','
          })
        }
      }
      name = name.slice(0, name.length - 1)
      if (!name) {
        name = book.nameCN
      }
    }
    return name
  },
  getGroupName2(g, group, prefilter) {
    let name = ''
    // 日期
    if (g.dataType === 'Date') {
      if (g.mode === 'Normal') {
        name = linkageDrillJS.dateInit(g, group, prefilter)
      }
    } else {
      name = group.name
    }
    name = name ? name : group.name
    return name
  },
  dateInit2(g, group, prefilter) {
    let name = ''
    if (g.preset === 'day') {
      name = '按天'
    }
    if (g.preset === 'week') {
      name = '按周'
    }
    if (g.preset === 'month') {
      name = '按月'
    }
    if (g.preset === 'year') {
      name = '按年'
    }
    if (g.preset === 'quarter') {
      name = '按季度'
    }
    // 自定义根据筛选条件 找到对应分组设置的名称
    if (['hour_range', 'datediff_range'].includes(g.preset)) {
      const conditions = prefilter.conditions
      if (conditions.length === 1) {
        if (conditions[0].operator === '>=') {
          const rang = g.ranges.find(x => x.start === conditions[0].values[0] && x.end === null)
          name = rang.alias
        }
        if (conditions[0].operator === '<') {
          const rang = g.ranges.find(x => x.end === conditions[0].values[0] && x.start === null)
          name = rang.alias
        }
      } else if (conditions.length === 2) {
        const rang = g.ranges.find(x => x.start === conditions[0].values[0] && x.end === conditions[1].values[0])
        name = rang.alias
      }
    }
    if (g.preset === 'raw_value') {
      const conditions = prefilter.conditions
      name = conditions[0].values[0]
    }
    return name
  },
  // 校验 前面卡片属性是否在新增卡片事件里面
  isCanDrill(drillFilter, el, bookmark) {
    let iscan = true
    if (!drillFilter) {
      return iscan
    }
    // 前面所有分组属性
    let allgroups = []
    // 下一级卡片
    let nextcard = ''
    if (drillFilter && drillFilter.conditions) {
      allgroups = allgroups.concat(drillFilter.conditions)
    }
    if (el.prefilter) {
      el.prefilter.forEach(x => {
        allgroups = allgroups.concat(x.conditions)
      })
      nextcard = bookmark.drillBookmarks[el.prefilter.length]
    } else {
      nextcard = bookmark.drillBookmarks[0]
    }
    // 所有属性
    let groupData = []
    if (nextcard.category == 'behavior') {
      const param = {
        source: 'behavior',
      }
      groupData = comJs.getgroupData(param, nextcard, 'Filter')
    } else if (nextcard.category == 'attribute') {
      const param = {
        source: 'entity',
      }
      groupData = comJs.getgroupData(param, nextcard, 'Filter')
    } else if (nextcard.category == 'template') {
      const param = {
        source: 'template',
      }
      groupData = comJs.getgroupData(param, nextcard, 'Filter')
    }
    // 所有属性
    let allproperties = []
    groupData.forEach(x => {
      x.child.forEach(c => {
        allproperties = allproperties.concat(c.properties)
      })
    })

    const allpropertiycodes = allproperties.map(x => x.code)
    allgroups.forEach(x => {
      if (!allpropertiycodes.includes(x.code)) {
        iscan = false
      }
    })

    return iscan || !nextcard.canVisit
  },
  isCanAddDrill(bookmark, cardlist, nextcard) {
    let iscan = true
    // 前面卡片所有分组
    let allgroups = []
    const beforeCard = [bookmark, ...cardlist]
    beforeCard.forEach(x => {
      if (x.category != 'template') {
        const definition = JSON.parse(x.definition)
        const groups = definition.parameter.groups
        if (groups && groups.length > 0) {
          allgroups = allgroups.concat(groups)
        }
      } else if (x.category == 'template') {
        const definition = JSON.parse(x.definition)
        const components = definition.parameter.components
        const groups = components.filter(c => c.type == 'Group')
        groups.forEach(g => {
          allgroups = allgroups.concat(g.groups)
        })
      }
    })
    // 新增卡片
    let groupData = []
    if (nextcard.category == 'behavior') {
      const param = {
        source: 'behavior',
      }
      groupData = comJs.getgroupData(param, nextcard, 'Filter')
    } else if (nextcard.category == 'attribute') {
      const param = {
        source: 'entity',
      }
      groupData = comJs.getgroupData(param, nextcard, 'Filter')
    } else if (nextcard.category == 'template') {
      const param = {
        source: 'template',
      }
      groupData = comJs.getgroupData(param, nextcard, 'Filter')
    }
    // 所有属性
    let allproperties = []
    groupData.forEach(x => {
      x.child.forEach(c => {
        allproperties = allproperties.concat(c.properties)
      })
    })
    const allpropertiycodes = allproperties.map(x => x.code)

    allgroups.forEach(x => {
      if (!allpropertiycodes.includes(x.code)) {
        iscan = false
      }
    })
    return iscan
  },
}

export default linkageDrillJS
