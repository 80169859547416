import tableUtil from './tableUtil'

import S1 from './head_closed'
import S2 from './head_full'
import S3 from './head_part'

class Table {
  constructor(params) {
    this.params = params
    // 行列实例
    this.styleSet = params.opts.styleSet || {}
    /**
     * 加工属性，初始化一次，提高性能
     * 统计类
     * 分组类
     */
    let getResultData = tableUtil.getResultData(this.styleSet, params.result, params.columns)
    this.params = Object.assign(params, getResultData)
  }
  init() {
    if (this.styleSet && this.styleSet.enablePivot === 'full') {
      return S2.init(this.params)
    } else if (this.styleSet && this.styleSet.enablePivot === 'part') {
      return S3.init(this.params)
    } else {
      return S1.init(this.params)
    }
  }
}

export default Table
