import tableUtil from './tableUtil'

// 部分转置 part
const S3 = {
  init(params) {
    this.params = params
    this.styleSet = params.opts.styleSet
    this.groups = params.groups
    // 开启对比
    this.prop = params.prop
    // 指标（同环，对比除外）
    this.m_prop = params.m_prop
    this.result = JSON.parse(JSON.stringify(params.result))
    this.eventDic = params.eventDic

    this.allDimValues = params.allDimValues
    this.firstDimValues = params.firstDimValues
    this.otherDimValues = params.otherDimValues
    this.Total = params.Total

    // 每列key集合
    this.theadKey_single = []

    // 第一列
    this.firstDim = this.groups[0].alias

    // 指标列新增占比率
    this.addOccThead()
    this.theadResult = this.thead()

    //排除占比
    const filterOcc = JSON.parse(JSON.stringify(this.theadResult)).filter(x => x.label !== '占比')

    return {
      thead: filterOcc,
      tbody: params.result.length === 0 ? [] : this.tbody(params.result),
      thead_orig: this.thead(this.styleSet.enableOccupation),
    }
  },
  /**
   * 部分转置 thead
   * data
   * [label:'', children:[{label:'', prop:''}]]
   *
   * 规则
   * prop指标1 > 分组2(M) > 分组3（N） 最大总个数（M * N） -> result会过滤为空的数据
   * prop指标2 > 分组2(M) > 分组3（N） 最大总个数（M * N） -> result会过滤为空的数据
   * 数据完整时单行列数 指标个数*（分组1*2*... ）
   *
   * otherDimValues 横向展示的分组值
   *
   * theadKey_single结构 {label:'', prop: ''}
   *
   */
  thead(orig) {
    let otherDimValues = this.otherDimValues
    // 每列key集合（分合并|非合并单元格）
    let theadKey = []
    const isenableComparison = this.styleSet.enableComparison || this.styleSet.contrastratio

    // 循环指标
    for (let m = 0, prop_len = this.prop.length; m < prop_len; m++) {
      let prop = this.prop[m]
      // 对比率指标关闭
      if (prop.usage === 'Rate' && !isenableComparison) {
        continue
      }

      // 循环每行数据
      if ((prop.usage === 'Contrast' && isenableComparison && this.styleSet.comparisonValue) || !isenableComparison || prop.usage !== 'Contrast') {
        for (let all_len = otherDimValues.length, i = 0; i < all_len; i++) {
          let arr = otherDimValues[i].split('[|]')
          // 插入指标放到head顶部
          arr.unshift(prop.title)
          // 给前一个对比值也添加指标
          let prevArr = (otherDimValues[i - 1] && otherDimValues[i - 1].split('[|]')) || []
          if (prevArr.length != 0) {
            prevArr.unshift(prop.title)
          }

          /**
           * 单条数据 key 由（指标，分组1,2,3组成 ）
           * tableUtil.getName (天津大悦城,未知 == 天津大悦城,未知)
           * 天津大悦城,未知,工作日 != 天津大悦城,未知,周末
           * j = 2 时 curName(天津大悦城,未知,工作日)
           */
          let j = 0

          while (j < arr.length) {
            let curName = tableUtil.getName(arr, j)
            let prevName = tableUtil.getName(prevArr, j)
            /**
             * 新增一个数组col 条件如下
             * 1. enableConflation 不合并单元格
             * 2. 与前一行数据不相等
             */

            /*
             * 加工bool值
             * 是否有车: 是
             * 非第一分组(加入label)
             */
            let name = arr[j]
            if (j !== 0 && this.groups[j] && this.groups[j].dataType === 'Boolean') {
              name = `${this.groups[j].title}: ${arr[j]}`
            }
            const propalias = orig
              ? `${otherDimValues[i]}[&]${prop.alias.includes('_occ') ? prop.alias : prop.alias + '_orig'}`
              : `${otherDimValues[i]}[&]${prop.alias}`
            let propBox = this.append(theadKey, j, name, propalias, prop)

            // 只有合并单元格和不同数据时才添加
            if (!this.styleSet.enableConflation || prevName !== curName) {
              propBox.tar.push(propBox.temp)
            }
            j++
          }
        }
      }
    }
    // 前插第一分组
    theadKey.splice(0, 0, {
      label: this.groups[0].title,
      prop: this.groups[0].alias,
      isGroup: true,
    })
    return theadKey
  },
  /**
   * prop（other分组的key） 作用：
   * 第一分组 + other分组 作为key 查找result 插入body
   *
   * i=2 索引2的分组 - 天津大悦城,未知,-》周末
   * result_idx + (prop_idx * result_len)
   * name 分组值
   */
  append(arr, i, name, propalias, prop) {
    // 头部对象赋值给tar
    let tar = arr
    let isLast = i === this.groups.length - 1
    let temp = {
      label: prop.unit && name == prop.title ? name + '(' + prop.unit + ')' : name,
    }

    // 最后一个分组加上prop属性 （根据element ui table 嵌套column 规则）
    if (isLast) {
      temp.prop = propalias
      // 每次都会保存每列的key(为body拼接数据用，根据key来从result中查找)
      if (prop.clickableType) {
        temp.clickableType = prop.clickableType
      }
      this.theadKey_single.push(temp)
    }
    /**
     * 利用对象引用类型特性 进入children 改变children
     * 根据分组索引得到children级别
     * 递归查找最后一级children
     * [label:'', children:[{label:'', prop:''}]]
     */
    while (i > 0) {
      // 存储当前级别children
      tar[tar.length - 1].children = tar[tar.length - 1].children || []
      tar = tar[tar.length - 1].children
      i--
    }
    return {
      tar,
      temp,
    }
  },
  /**
   *
   * @param {Array} result
   * @return [{0:x, label:1}, {1:x, label:2}]
   */
  tbody(result) {
    // 指标label
    let temp = []
    // 总计
    let TotalCol = {}
    this.firstDimValues.forEach(firstDimName => {
      let arr = {}
      // 第一分组插入第一列
      arr[this.firstDim] = firstDimName
      /**
       * 循环每个列头拼接对应的值
       * 单行结构(行-第一分组， 列-其他分组)
       * [{ prop: "2019-11-21 17:00:00.0,-|25001_user_fixed_user_mid_distinct" }]
       */
      this.theadKey_single.forEach(key => {
        let kprop = key.prop
        // 每列key
        let prop = kprop.split('[&]')
        // result中找到对应结果项
        let d = result.find(row => {
          return row.sys_gen_key === firstDimName + '[|]' + prop[0]
        })
        if (d === undefined) {
          return true
        }
        let v = d[prop[1]]
        arr[kprop] = v
        // 每列总计

        TotalCol[kprop] = TotalCol[kprop] || { total: 0, extLen: 0, allLen: 0, values: [], median: 0 }
        TotalCol[kprop]['values'].push(v)
        if (v) {
          TotalCol[kprop]['total'] += v
        }
        TotalCol[kprop]['allLen'] += 1
        if (v === 0) {
          TotalCol[kprop]['extLen'] += 1
        }

        if (this.styleSet.aggregation === 'median') {
          TotalCol[kprop]['median'] = this.getmedian(TotalCol[kprop]['values']) || 0
        }
      })
      temp.push(arr)
    })
    this.TotalCol = TotalCol
    this.addCount(temp)

    // 添加占比
    this.addOccBody(temp)
    return temp
  },
  // 增加总计row
  addCount(body) {
    let aggr = this.styleSet.aggregation
    if (aggr && aggr === 'none') {
      return
    }
    let total = this.TotalCol
    let temp = {
      [this.firstDim]: tableUtil.labelEnum[aggr],
      sum: true,
    }
    this.theadKey_single.forEach(key => {
      let v = total[key.prop].total
      // let len = this.firstDimValues.length
      let allLen = total[key.prop].allLen
      const cutlen = allLen - total[key.prop].extLen
      // 比例的总计为空占位符
      if (key.prop.includes('Rate')) {
        temp[key.prop] = S3.getSummary(key, total)
        return false
      }
      if (aggr === 'average') {
        v = v / allLen
      } else if (aggr === 'median') {
        v = total[key.prop].median
      } else if (aggr === 'averageExt') {
        v = cutlen ? v / cutlen : v
      }
      let isFloat = v !== parseInt(v)
      temp[key.prop] = Number(isFloat ? v.toFixed(2) : v)
    })
    body.unshift(temp)
  },
  // 新增占比率头
  addOccThead() {
    // 指标列新增占比率
    let arr = []
    if (this.styleSet.enableOccupation) {
      this.prop.forEach(item => {
        arr.push(item)
        // 同环比对比指标前不加占比
        if (item.usage === 'Rate') {
          return false
        }
        arr.push({
          alias: `${item.alias}_occ`,
          title: '占比',
        })
      })
      this.prop = arr
    }
  },
  /**
   * 新增占比率内容
   * 根据occ关键字找到 k -> v
   * 46|distinct_totalcount_10002: 4323825
   * 46|distinct_totalcount_10002_occ: undefined
   * @param {*} body
   */
  addOccBody(body) {
    let Total = this.Total
    if (this.styleSet.enableOccupation) {
      body.forEach(row => {
        Object.keys(row).forEach(item => {
          // occ项
          if (item.includes('occ')) {
            // 指标值项
            let k = item.replace('_occ', '')
            const occval = tableUtil.countRate(row[k], Total[k.split('[&]')[1]].num)
            row[k + '_orig'] = row[k]
            row[k + '_occ'] = occval
            row[k] += `(${occval})`
          }
        })
      })
    }
  },
  getmedian(values) {
    values.sort((a, b) => {
      return a - b
    })
    const valLens = values.length
    const isEven = Number.isInteger(valLens / 2)
    const median = values.length > 0 && isEven ? (values[valLens / 2] + values[valLens / 2 - 1]) / 2 : values[(valLens - 1) / 2]
    return median
  },
  // 汇总的 对比，同比，环比处理
  getSummary(key, total) {
    const prop = key.prop
    //比值
    const contrastprop = prop.replace('Rate', '')
    const contrastVal = total[contrastprop]?.total ?? 0
    //原始值
    const origprop = contrastprop.split('_')[0]
    const origVal = total[origprop].total
    // (原始值 - 比值) /比值
    return tableUtil.countRate(origVal - contrastVal, contrastVal)
  },
}
export default S3
