import { Search2, getMallMap, getAdvanceSearch, getAdvanceSearch2, cardSearch, getTemplateByCode } from '@/api/apiV2_dashboard'
import bi_echarts from 'mallcoo_bi_echarts'
const Table = bi_echarts.Table
import { allColor } from '@/datajs/static.js'
import commonJs from '@/script/common.js'
import initMarkV2 from '@/script/bookmarkV2/initMarkV2'
import store from '@/store'

// 多卡组合
let cardinitMark = {
  init(_this, BI_config, v, el, i, cardkey) {
    let definition = JSON.parse(el.definition)
    let reqObj = JSON.parse(JSON.stringify(definition))
    reqObj.content = el.content
    reqObj['setting'] = reqObj.settings
    reqObj.title = reqObj.settings.nameCN
    reqObj.cardGrid = []
    reqObj.cardlist = []

    reqObj.parameter.analyses.forEach((card, cardidx) => {
      let cardpos = card.position
      let cardposlist = {
        id: card.id,
        x: cardpos.x || cardpos.x == 0 ? cardpos.x : 4,
        y: cardpos.y || cardpos.y == 0 ? cardpos.y : 4,
        w: cardpos.row || cardpos.row == 0 ? cardpos.row : 4,
        h: cardpos.col || cardpos.col == 0 ? cardpos.col : 4,
        i: cardidx,
      }
      reqObj.cardGrid[cardidx] = cardposlist

      if (card.settings.type === 'word') {
        const cardchart = {
          index: cardidx,
          cardtype: card.settings.type,
          bookmark: card,
          settings: card.settings,
          option: card.words,
          chartkey: cardidx,
        }
        reqObj.cardlist[cardidx] = cardchart
      } else {
        let cardreqObj = JSON.parse(card.definition)
        let defcode = card.category
        let themeSet = card.settings.themeSet
        cardreqObj.settings = card.settings
        cardreqObj.mode = card.settings.type
        cardreqObj.title = reqObj.title

        // 卡片主题设置 chartMainColor 为 0 跟随系统(_this.basicInfo.project.mainColor) 否则（themeSet.chartMainColor）
        // 项目主题 mainColor 0
        const chartMainColor = (themeSet && parseInt(themeSet.chartMainColor)) || 0
        const categoryColor = (themeSet && parseInt(themeSet.categoryColor)) || 0
        let cardmainColoridx = chartMainColor > 0 ? chartMainColor : _this.basicInfo.project.mainColor
        let mainColor = chartMainColor > 0 ? allColor['color' + (cardmainColoridx - 1)] : allColor['color' + cardmainColoridx]
        const cardchart = {
          index: cardidx,
          cardtype: card.settings.type,
          bookmark: card,
          settings: card.settings,
          option: '',
          searchCode: card.category,
          reqObj: cardreqObj,
          rdMainColor: chartMainColor,
          chartColorV2: mainColor[categoryColor],
          rdChartColor: categoryColor,
          version: '2.0',
          chartkey: cardidx,
        }
        reqObj.cardlist[cardidx] = cardchart
      }
    })

    // themeSet = reqObj.setting.themeSet
    reqObj.contrastType =
      reqObj.parameter.contrastType == 'Current' || !reqObj.parameter.contrastType ? 'nocontrast' : reqObj.parameter.contrastType.toLowerCase()
    let timeRange = reqObj.parameter.timeRanges
    // if (el.category == 'template') {
    //   let timeComp = definition.parameter.components.find(x => {
    //     return x.type == 'TimePicker'
    //   })
    //   timeRange = [timeComp.timeRange]
    // }

    // if (reqObj.contrastType == 'lastCircle') {
    //   reqObj.contrastType = 'circle'
    // }
    // reqObj.contrastType = reqObj.contrastType ? reqObj.contrastType.toLowerCase() : ''

    reqObj.injectedArguments = {
      conditionGroup: {
        conditions: [],
        operator: 'and',
      },
    }

    //得到网格数据  //PositionV2
    //若没有 positionV2 获取position
    let posv2 = el.positionV2
    if (!posv2) {
      posv2 = el.position
    }
    let pos = {
      id: el.id,
      x: posv2.col || posv2.col == 0 ? posv2.col : 4,
      y: posv2.row || posv2.row == 0 ? posv2.row : 4,
      w: posv2.x || posv2.x == 0 ? posv2.x : 4,
      h: posv2.y || posv2.y == 0 ? posv2.y : 4,
      i: i,
    }
    _this.position[i] = pos
    // 卡片主题设置 chartMainColor 为 0 跟随系统(_this.basicInfo.project.mainColor) 否则（themeSet.chartMainColor）
    // 项目主题 mainColor 0
    // let mainColoridx = parseInt(themeSet.chartMainColor) > 0 ? parseInt(themeSet.chartMainColor) : _this.basicInfo.project.mainColor
    // let mainColor = parseInt(themeSet.chartMainColor) > 0 ? allColor['color' + (mainColoridx - 1)] : allColor['color' + mainColoridx]
    let url = ''
    let path = el.category + '/' + store.state.projectID + '/' + el.id
    url = BI_config.analysisUrl + BI_config.analysisProxy + '/' + path
    let chart = {
      index: i,
      id: el.id,
      lock: el.lock,
      canVisit: el.canVisit,
      cardkey: cardkey ? cardkey : 0,
      mapkey: i + '-',
      code: el.code,
      searchCode: el.category,
      reqObj: reqObj,
      option: null,
      timeRange: timeRange,
      loading: true,
      url: url,
      otherset: {},
      version: el.version,
      // rdMainColor: parseInt(themeSet.chartMainColor),
      // chartColorV2: mainColor[parseInt(themeSet.categoryColor)],
      // rdChartColor: parseInt(themeSet.categoryColor),
    }
    this.getTimePickerIntervals(el).then(r => {
      chart.timePickerIntervals = r
    })
    let getslot = []
    let slots = []
    if (reqObj.parameter.groups) {
      getslot = reqObj.parameter.groups.filter((g, slotidx) => {
        return g.code == 'time' && g.mode == 'Normal' && slotidx == 0
      })
      slots = getslot.length > 0 ? getslot[0].preset.split('_') : ''
    }
    chart.otherset.slot = getslot.length > 0 ? (slots.length > 1 ? slots[1] : slots[0]) : null

    return chart
  },
  searchRequest: (_this, index, scheduler, isinit, time) => {
    let definition = JSON.parse(_this.bookmarks[index].definition)
    _this.chartData[index].loading = true

    definition.parameter.analyses.forEach((card, cardidx) => {
      if (card.settings.type === 'word') {
        _this.chartData[index].reqObj.cardlist[cardidx].option = _this.chartData[index].reqObj.cardlist[cardidx].settings.styleSet.wordvalues
        cardinitMark.isloading(_this, index, isinit)
      } else {
        let carddef = JSON.parse(card.definition)
        let p = carddef
        let temp = card.category.slice(0, 1).toUpperCase() + card.category.slice(1)
        if (scheduler) {
          scheduler.add(() => {
            return cardinitMark.getSearch(_this, index, cardidx, temp, p, card, isinit, time)
          })
        } else {
          cardinitMark.getSearch(_this, index, cardidx, temp, p, card, isinit, time)
        }
      }
    })

    // _this.copyChartData[index]['query'] = p
  },
  async getSearch(_this, index, cardidx, temp, p, card, isinit, time, refresh) {
    const reqObj = _this.chartData[index].reqObj
    let timerang = time ? time : reqObj.parameter.timeRanges
    let param = JSON.parse(JSON.stringify(p.parameter))
    if (card.category == 'template') {
      let timecomp = param.components.find(x => {
        return x.type === 'TimePicker'
      })
      if (timecomp) {
        timecomp.timeRange = timerang[0]
      }
    }

    if (refresh == 'refresh') {
      param.forceRefresh = true
    }
    if (param.timeRanges) {
      param.timeRanges = reqObj.parameter.timeRanges
      if (param.contrastType) {
        const contrastType = reqObj.contrastType
        param.contrastType = contrastType === 'nocontrast' ? 'Current' : contrastType
      }
    }
    if (reqObj.injectedArguments && reqObj.injectedArguments.prefilter) {
      // 过滤条件
      param.prefilter = reqObj.injectedArguments.prefilter
    }
    return cardSearch(card.category, param)
      .then(async res => {
        if (temp == 'Advance') {
          let d = res
          let coderes = ''
          if (d && d.code == '201') {
            coderes = await getAdvanceSearch2(d.data.requestID)
            if (coderes.code == '200') {
              cardinitMark.searchEnd(_this, index, cardidx, temp, p, card, coderes.data, '', isinit)
            }
          } else if (d.code == '200') {
            cardinitMark.searchEnd(_this, index, cardidx, temp, p, card, d.data, '', isinit)
          }
          if (coderes && coderes.code == '202') {
            let searchRes = ''
            searchRes = await getAdvanceSearch2(d.data.requestID)
            if (searchRes && searchRes.code == '202') {
              let searchtimes = 1
              cardinitMark.recurrence(_this, searchRes, d, index, cardidx, temp, p, card, isinit, searchtimes)
            } else if (searchRes.code == '200') {
              d = searchRes
              cardinitMark.searchEnd(_this, index, cardidx, temp, p, card, d.data, '', isinit)
            }
          }
        } else {
          cardinitMark.searchEnd(_this, index, cardidx, temp, p, card, res.data, '', isinit)
        }
      })
      .catch(() => {
        if (_this.chartData[index]) _this.chartData[index].loading = false
      })
  },
  async recurrence(_this, searchRes, d, index, cardidx, temp, p, card, isinit, searchtimes) {
    if (searchtimes >= 90) {
      _this.$alert(`查询超时`, '提示', {
        confirmButtonText: '确定',
      })
      return
    }
    try {
      searchRes = await getAdvanceSearch2(d.data.requestID)
    } catch (e) {}
    if (searchRes && searchRes.code == '200') {
      d = searchRes
      cardinitMark.searchEnd(_this, index, cardidx, temp, p, card, d.data, '', isinit)
    } else if (searchRes && searchRes.code == '202') {
      setTimeout(() => {
        searchtimes++
        return cardinitMark.recurrence(_this, searchRes, d, index, cardidx, temp, p, card, isinit, searchtimes)
      }, 2000)
    }
  },
  searchEnd(_this, idx, cardidx, temp, p, card, res, hotmapdata, isinit) {
    let actualLength = res.rows.length
    res.rows = res.rows.slice(0, 10000)
    const filterSet = _this.chartData[idx].reqObj.cardlist[cardidx].reqObj.settings.filterSet
    let result = filterSet ? cardinitMark.getFilterData2(res, _this.chartData[idx].reqObj.cardlist[cardidx]) : res

    result.actualLength = actualLength
    let option = null
    let opts = {
      ...card.settings,
      analysisType: temp,
    }
    let tableObj = new Table({
      opts,
      columns: result.columns,
      result: result.rows.slice(0, 10000),
    })
    //Table 内部修改了rows 需要同步更新d1
    result.rows = tableObj.params.result
    p.settings = card.settings
    option = {}
    option.noData = res.rows.length > 0 ? false : true
    const tableObjdata = tableObj.init()

    _this.chartData[idx].reqObj.cardlist[cardidx]['data'] = result
    _this.chartData[idx].reqObj.cardlist[cardidx]['orginResult'] = result

    _this.chartData[idx].reqObj.cardlist[cardidx]['tableObj'] = tableObj
    _this.chartData[idx].reqObj.cardlist[cardidx]['inittableObj'] = tableObjdata
    let excelsObj = initMarkV2.getExcelData(tableObjdata)
    _this.chartData[idx].reqObj.cardlist[cardidx]['excelsObj'] = excelsObj

    _this.chartData[idx].reqObj.cardlist[cardidx]['resizedidx'] = 0

    // 如果没有名称设置 增加默认名称设置
    // 兼容早期卡片 没有 propSet ，从原始 columns 和 filterset 获取 分组和指标
    const propsets = p.settings.propSet
    if (!propsets && result.columns.length > 0 && p.settings.type !== 'table_more' && p.settings.type !== 'funnel') {
      const groupColumns = result.columns.filter(x => x.usage === 'Group')
      const respropColumns = result.columns.filter(x => x.usage === 'Measure')
      if (!p.settings.filterSet.columns) {
        p.settings.filterSet.columns = []
      }
      const filterprops = p.settings.filterSet.columns.filter(x => !x.usage || x.usage === 'Measure')
      const groups = groupColumns.map(x => {
        return {
          alias: x.alias,
          fullAlias: x.fullAlias,
          title: x.title,
        }
      })
      const props = filterprops.map(x => {
        const targ = respropColumns.find(t => t.fullAlias == x.fullAlias)
        const displayType = (targ && (targ.displayType || targ.dataType)) || ''
        const format = {
          thousands: true, // 千分位
          percent: targ && ['PERCENTAGE', 'PERCENTAGE_DIRECT', 'PERCENTAGE_INTERGER'].includes(displayType), // 百分比
          // 小数点位数,null 不做处理，0，1，2，3 是保留几位小数
          digit: ['PERCENTAGE', 'PERCENTAGE_DIRECT'].includes(displayType) ? 2 : displayType == 'PERCENTAGE_INTERGER' ? 0 : null,
        }
        return {
          alias: x.alias,
          fullAlias: x.fullAlias,
          title: x.name,
          format,
        }
      })

      const propsets = {
        groups,
        props,
      }
      p.settings.propSet = propsets
    }
    // 如果图表没有Y轴样式设置 增加默认设置
    if (['line', 'bar', 'bar_line', 'scatter', 'bubble', 'biaxial_graph'].includes(p.settings.type) && !p.settings.styleSet.yAxisformat) {
      p.settings.styleSet.yAxisNumUnit = 'none'
      p.settings.styleSet.yAxisformat = { thousands: true, percent: false, digit: null }
    }
    p.settings = commonJs.compatibleHistory(p.settings)
    _this.chartData[idx].reqObj.cardlist[cardidx]['settings'] = p.settings
    const iscardinfos = _this.chartData[idx].reqObj.cardlist[cardidx].cardinfos || ''
    let cardinfos = {
      index: cardidx,
      cardkey: iscardinfos ? iscardinfos.cardkey + 1 : String(cardidx),
      cardresizekey: iscardinfos ? iscardinfos.cardresizekey + 1 : String(idx),
      parameter: p.parameter,
      settings: p.settings,
      result,
      hotmapdata,
      projectopts: {
        basicInfo: _this.basicInfo,
        theme: _this.basicInfo.project.theme,
        mainColor: _this.basicInfo.project.mainColor,
        pageunit: _this.basicInfo.project.axisUnit,
        contrastTrendColor: _this.basicInfo.project.contrastTrendColor,
        projectMalls: _this.projectMalls,
        weathers: store.state.weathers,
        festivals: store.state.festivals,
      },
      cardopts: {
        searchCode: _this.chartData[idx].searchCode,
        category: temp,
      },
    }
    if (temp.toLowerCase() == 'segment') {
      cardinfos.cardopts.uniqueID = result.uniqueID
      cardinfos.cardopts.segmentName = p.settings.nameCN || p.settings.nameEN
    }
    _this.$set(_this.chartData[idx].reqObj.cardlist[cardidx], 'cardinfos', cardinfos)

    // option.color = cardinitMark.initChartColor(_this, _this.chartData[idx].reqObj.cardlist[cardidx])
    _this.$set(_this.chartData[idx].reqObj.cardlist[cardidx], 'option', option)
    // if (opts.type === 'card' && option && option.arr.length > 0) {
    //   _this.$set(_this.chartData[idx].reqObj.cardlist[cardidx], 'curcard', option.arr[0])
    //   _this.$set(_this.chartData[idx].reqObj.cardlist[cardidx], 'curcardlabel', option.arr[0].label)
    // }
    cardinitMark.isloading(_this, idx, isinit)
  },
  getFilterData2: (d, chart) => {
    let copyres = _.cloneDeep(d)
    let filterSet = chart.reqObj.settings.filterSet
    const propsetGroup = chart.reqObj.settings?.propSet?.groups ?? []

    let groups = copyres.columns.filter(x => {
      return x.usage == 'Group'
    })
    let measures = copyres.columns.filter(x => {
      return x.usage == 'Measure'
    })
    let rows = copyres.rows.filter(x => {
      let s = ''
      let isUnknown = false
      groups.forEach((g, gidx) => {
        //隐藏未知
        if (filterSet.hideUnknown) {
          if (x[g.alias] == '-') {
            isUnknown = true
          }
        }
        if (gidx < groups.length - 1 && groups.length > 1) {
          s += x[g.alias] + '[|]'
        } else {
          s += x[g.alias]
        }
      })
      x.sys_gen_key = s
      // 指标类型 PERCENTAGE_DIRECT 的值 *0.01
      if (!x.per_direct) {
        copyres.columns.forEach(m => {
          if (m.displayType == 'PERCENTAGE_DIRECT') {
            x[m.alias] = x[m.alias] * 0.01
          }
        })
        x.per_direct = true
      }
      return x && !isUnknown
    })
    let filterdata = d

    if (copyres.columns.length > 0) {
      let oldname = ''
      filterdata.columns = copyres.columns.filter(x => {
        if (x.usage === 'Group') {
          const grp = propsetGroup.find(g => g.fullAlias === x.fullAlias)
          if (grp) x.title = grp.title
        }
        let targ = filterSet.columns.find(t => {
          if (t.fullAlias == x.fullAlias) {
            oldname = x.title
          }
          return t.fullAlias == x.fullAlias
        })
        let isshow = targ && (!targ.usage || targ.usage === 'Measure') ? targ.selected : true
        x.title = targ && (!targ.usage || targ.usage === 'Measure') ? targ.name : cardinitMark.reviseName(copyres, x)
        if (!targ) {
          let meatarg =
            filterSet &&
            filterSet.columns &&
            filterSet.columns.find(t => {
              return x.alias.includes(t.alias)
            })
          if (meatarg) {
            x.title = x.title.replace(oldname, meatarg.name)
          }
        }
        return isshow
      })

      //指标数值过滤开启时，根据指标start，end过滤数值
      // if (filterSet.filterValue) {
      //   rows = rows.filter(x => {
      //     let istrue = true
      //     filterSet.columns.forEach(c => {
      //       let val =
      //         c.filterCode && x[c.filterCode]
      //           ? typeof x[c.filterCode] === 'number'
      //             ? x[c.filterCode]
      //             : x[c.filterCode] == '-'
      //             ? '-'
      //             : parseFloat(x[c.filterCode].replace(/[,]/g, ''))
      //           : x[c.alias]
      //       if (c.start && c.end && c.start <= c.end && val >= c.start && val <= c.end) {
      //       } else if (c.start && c.end && c.start > c.end && val >= c.start) {
      //       } else if (!c.start && c.end && val <= c.end) {
      //       } else if (c.start && !c.end && val >= c.start) {
      //       } else if (!c.start && !c.end) {
      //       } else {
      //         istrue = false
      //       }
      //     })
      //     return istrue
      //   })
      // }
      filterdata.rows = rows
      // 漏斗
      if (filterdata.funnels) {
        let funnels = filterdata.funnels.map(x => {
          let itemres = initMarkV2.getFunnelSteps(x, chart.reqObj.settings)
          return itemres
        })
        filterdata.funnels = funnels
      }
    } else {
      filterdata = copyres
      filterdata.rows = rows
    }
    if (copyres.timeRanges) {
      filterdata.timeRanges = copyres.timeRanges
    }
    if (copyres.uniqueID) {
      filterdata.uniqueID = copyres.uniqueID
    }
    // 如果分组名设置了不可见
    const groupformatlist = propsetGroup.filter(x => x.groupformat && x.groupformat.length > 0)
    if (groupformatlist) {
      filterdata.rows.forEach(x => {
        groupformatlist.forEach(g => {
          g.groupformat.forEach(f => {
            x[g.alias] = x[g.alias].replace(f, '')
          })
        })
      })
    }
    return filterdata
  },
  reviseName(d, column) {
    let name = column.title
    //是否同环比值
    d.columns.forEach(x => {
      if (x.alias + '_contrast' == column.alias) {
        name = '对比' + x.title
      }
      if (x.alias + '_last' == column.alias) {
        name = '去年同期' + x.title
      }
      if (x.alias + '_circle' == column.alias) {
        name = '上一周期' + x.title
      }
    })
    return name
  },
  initChartColor(_this, cur) {
    let mainColoridx = 0
    let mainColor = null
    let chartColoridx = 0
    mainColoridx = cur.rdMainColor != null && cur.rdMainColor != '0' ? cur.rdMainColor : store.state.basicInfo.project.mainColor

    mainColor = cur.rdMainColor > 0 ? allColor['color' + (mainColoridx - 1)] : allColor['color' + mainColoridx]
    chartColoridx = cur.rdChartColor != null ? cur.rdChartColor : 0
    return mainColor[chartColoridx]
  },
  //时间改变 清除option 重新请求 所有option得到后 取消loading
  gettimeRange(_this, em, BI_config, slot, iscontrast, scheduler) {
    let cardkey = _this.chartData[em.index].cardkey
    _this.$set(_this.chartData[em.index], 'option', false)
    // let chart = cardinitMark.init(_this, BI_config, '', _this.bookmarks[em.index], em.index, cardkey)
    const chart = _this.chartData[em.index]
    let isRelative = em.v2time[0].mode

    let v2item = {}
    v2item.v2time = chart.reqObj.parameter.timeRanges
    if (chart.reqObj.contrastType == 'contrast') {
      v2item.v2time = cardinitMark.getV2timeRan(_this, chart.reqObj, em.v2time)
    } else {
      v2item.v2time = [em.v2time[0]]
    }
    let timeRange = cardinitMark.getContrastTimeV2(_this, chart.reqObj, v2item, isRelative, iscontrast)

    chart.timeRange = timeRange
    chart.reqObj.timeRange = timeRange
    chart.reqObj.parameter.timeRanges = timeRange
    _this.$set(_this.chartData, em.index, chart)
    cardinitMark.searchRequest(_this, em.index, scheduler, '', em.v2time, scheduler)
  },
  isloading(_this, idx, isinit) {
    const noopt = _this.chartData[idx].reqObj.cardlist.find(x => {
      return !x.option
    })
    if (!noopt) {
      _this.$set(_this.chartData[idx], 'loading', false)
      _this.$set(_this.chartData[idx], 'option', true)
      if (!isinit) {
        _this.chartData[idx].cardkey++
      }
    }
  },
  // 刷新的时候 重新请求
  searchchange(_this, index, contrastitem, type) {
    // let definition = JSON.parse(_this.bookmarks[index].definition)
    _this.chartData[index].reqObj.cardlist.forEach(x => {
      x.option = null
    })
    _this.chartData[index].loading = true

    // _this.$set(_this.chartData[index], 'timeRange', definition.parameter.timeRanges)
    const chart = _this.chartData[index]
    const reqObj = chart.reqObj
    reqObj.cardlist.forEach((card, cardidx) => {
      if (card.cardtype === 'word') {
        card.option = card.settings.styleSet.wordvalues
        cardinitMark.isloading(_this, index)
      } else {
        let p = card.reqObj
        let temp = card.bookmark.category == 'template' ? p.parameter.usage : card.bookmark.category.slice(0, 1).toUpperCase() + card.bookmark.category.slice(1)

        if (type == 'contrast' || type == 'Contrast') {
          let contrast = reqObj.contrastType
          let v2item = {}
          v2item.v2time = reqObj.parameter.timeRanges
          switch (contrast) {
            case 'nocontrast':
              contrast = 'Current'
              break
            case 'contrast':
              contrast = 'Contrast'
              var timeRa = reqObj.parameter.timeRanges
              v2item.v2time = cardinitMark.getV2timeRan(_this, reqObj, timeRa)
              break
            case 'last':
              contrast = 'Last'
              break
            case 'circle':
              contrast = 'Circle'
              break
            case 'lastAndCircle':
              contrast = 'LastAndCircle'
              break
          }
          if (contrast != 'Contrast') {
            v2item.v2time = [v2item.v2time[0]]
          }
          let timeRan = cardinitMark.getContrastTimeV2(_this, reqObj, v2item)

          chart.timeRange = timeRan
          reqObj.timeRange = timeRan
          reqObj.parameter.timeRanges = timeRan
          p.parameter.contrastType = contrast

          cardinitMark.getSearch(_this, index, cardidx, temp, p, card.bookmark, '', '', type)
        } else {
          cardinitMark.getSearch(_this, index, cardidx, temp, p, card.bookmark, '', '', type)
        }
      }
    })

    // definition.parameter.analyses.forEach((card, cardidx) => {
    //   if (card.settings.type === 'word') {
    //     _this.chartData[index].reqObj.cardlist[cardidx].option = _this.chartData[index].reqObj.cardlist[cardidx].settings.styleSet.wordvalues
    //     cardinitMark.isloading(_this, index)
    //   } else {
    //     let carddef = JSON.parse(card.definition)
    //     let p = carddef
    //     let temp = card.category == 'template' ? p.parameter.usage : card.category.slice(0, 1).toUpperCase() + card.category.slice(1)

    //     cardinitMark.getSearch(_this, index, cardidx, temp, p, card, '', '', type)
    //   }
    // })
  },

  async getTimePickerIntervals(el) {
    let timePickerIntervals = []
    if (el.category == 'template') {
      let templateCode = JSON.parse(el.definition).parameter.code
      let template = (await getTemplateByCode(templateCode)).data
      let tComponents = template.definition.components
      var bComponents = JSON.parse(el.definition).parameter.components
      bComponents
        .filter(x => x.type == 'TimePicker')
        .forEach(x => {
          let timeComponent = tComponents.find(y => y.code == x.code)
          if (timeComponent) {
            timePickerIntervals.push(timeComponent.settings.interval)
          } else {
            timePickerIntervals.push('-1')
          }
        })
    }
    return timePickerIntervals
  },
  getContrastTimeV2: (_this, req, emit, isRelative, iscontrast) => {
    //如果修改的是对比时间，获取当前时间参数并修改对比时间
    //否则获取时间参数 如果有对比 第二个时间不修改否则计算对比时间
    if (iscontrast) {
      let newTime = req.parameter.timeRanges
      newTime[1] = emit.v2time[0]
      return newTime
    } else {
      let timeRange = emit && emit.v2time ? emit.v2time : req.parameter.timeRanges
      // let timeRange = req.parameter.timeRanges
      // const v2time = emit && emit.v2time
      let times = JSON.parse(JSON.stringify(timeRange))
      let newTime = []
      newTime.push(times[0])
      if (req.contrastType == 'contrast') {
        newTime.push(times[1])
      }
      return newTime
    }
  },
  getV2timeRan(_this, req, timeRange) {
    let time = timeRange
    let t1 = JSON.parse(JSON.stringify(time[0]))
    let t2 = {}
    if (t1.mode == 'Relative') {
      let showtime = commonJs.getshowdata(_this, t1)
      t2.start = showtime[0]
      t2.end = showtime[1]
      t2.mode = 'Absolute'
    } else {
      t2 = t1
    }
    const diff_days = _this.moment(t2.end).diff(_this.moment(t2.start), 'days')
    t2.end = _this
      .moment(t2.start)
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
    const date2 = _this
      .moment(t2.start)
      .subtract(diff_days + 1, 'days')
      .format('YYYY-MM-DD')
    t2.start = date2
    if (!time[1]) {
      time[1] = t2
    }
    return time
  },
}

export default cardinitMark
