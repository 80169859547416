import initMarkV2 from '@/script/bookmarkV2/initMarkV2'
import comJs from './com'
import linkageDrillJS from './linkagedrill'
import { Search2 } from '@/api/apiV2_dashboard'

let linkageJS = {
  getlastgroup(bookmark) {
    let lastgroup = ''
    const groups = linkageJS.getGroupData(bookmark, 'groups')
    if (groups && groups.length > 0) {
      lastgroup = groups[groups.length - 1]
    }
    return lastgroup
  },
  getGroupData(bookmark, isgroups) {
    const definition = JSON.parse(bookmark.definition)
    let groups = []
    let groupData = []
    // 事件
    if (bookmark.category === 'behavior') {
      groups = definition.parameter.groups
      const param = {
        source: 'behavior',
      }
      groupData = comJs.getgroupData(param, bookmark, 'Filter')
    } else if (bookmark.category === 'attribute') {
      groups = definition.parameter.groups
      const param = {
        source: 'entity',
      }
      groupData = comJs.getgroupData(param, bookmark, 'Filter')
    } else if (bookmark.category === 'template') {
      const components = definition.parameter.components
      const findGroupType = components.filter(x => x.type === 'Group')
      findGroupType.forEach(g => {
        groups = groups.concat(g.groups)
      })
      const param = {
        source: 'template',
      }
      groupData = comJs.getgroupData(param, bookmark, 'Filter')
    }
    let allproperties = []
    groupData.forEach(x => {
      x.child.forEach(c => {
        allproperties = allproperties.concat(c.properties)
      })
    })

    if (isgroups) {
      groups = groups.map(x => {
        const findgroup = allproperties.find(p => p.code === x.code)
        x.name = findgroup.name
        return x
      })
    }
    return isgroups ? groups : groupData
  },
  searchchange(_this, idx, req, contrastitem, type, curbook) {
    let bookmark = curbook
    let definition = JSON.parse(bookmark.definition)
    let temp = ''
    let p = ''
    let p2 = {}
    temp = bookmark.category.slice(0, 1).toUpperCase() + bookmark.category.slice(1)
    p2 = definition
    _this.chartData[idx].loading = true
    _this.chartData[idx].option = null

    let inject = req.injectedArguments
    if (type == 'contrast' || type == 'Contrast') {
      let contrast = req.contrastType
      let v2item = {}
      v2item.v2time = inject && inject.timeRange ? inject.timeRange : req.parameter.timeRanges
      switch (contrast) {
        case 'nocontrast':
          contrast = 'Current'
          break
        case 'contrast':
          contrast = 'Contrast'
          var timeRa = inject && inject.timeRange ? inject.timeRange : req.parameter.timeRanges
          v2item.v2time = initMarkV2.getV2timeRan(_this, req, timeRa)
          break
        case 'last':
          contrast = 'Last'
          break
        case 'circle':
          contrast = 'Circle'
          break
        case 'lastAndCircle':
          contrast = 'LastAndCircle'
          break
      }
      let timeRan = _this.$commonJs.getContrastTimeV2(_this, req, v2item)
      inject.timeRange = timeRan
      inject.contrastType = contrast
      _this.chartData[idx].timeRange = timeRan
      p2.ContrastType = contrast
    } else if (type == 'slot') {
      inject.timeRange = JSON.parse(JSON.stringify(inject.timeRange || req.parameter.timeRanges))
      let contrast = inject.contrastType ? inject.contrastType : req.contrastType
      switch (contrast) {
        case 'nocontrast':
          contrast = 'Current'
          break
        case 'contrast':
          contrast = 'Contrast'
          break
        case 'last':
          contrast = 'Last'
          break
        case 'circle':
          contrast = 'Circle'
          break
        case 'lastAndCircle':
          contrast = 'LastAndCircle'
          break
      }
      inject.contrastType = contrast
      inject.selectedGroups = [{ code: 'eventTime', type: 'date', category: 3, value: contrastitem, text: '事件发生时间' }]
      p2.ContrastType = contrast
    } else if (type == 'mallid') {
      p.conditionGroup = JSON.parse(JSON.stringify(req.conditionGroup))
      inject.conditionGroup = JSON.parse(JSON.stringify(req.conditionGroup))
      inject.timeRange = JSON.parse(JSON.stringify(inject.timeRange || req.parameter.timeRanges))
    } else if (type == 'refresh') {
      if (inject.timeRange || req.parameter.timeRanges) {
        inject.timeRange = JSON.parse(JSON.stringify(inject.timeRange || req.parameter.timeRanges))
      }
    }
    if (inject && inject.timeRange) {
      req.timeRange = inject.timeRange
    }
    _this.$set(_this.chartData[idx], 'reqObj', req)
    if (inject.timeRange) {
      _this.$set(_this.chartData[idx], 'timeRange', inject.timeRange)
    }
    linkageJS.getSearch2(_this, idx, temp, p2, 'search', req, type, curbook)
  },
  async getSearch2(_this, idx, temp, p, search, req, refresh, curbook) {
    let bookmark = _this.bookmarks[idx]
    let param = {
      id: (curbook && curbook.id) || (bookmark && bookmark.id),
    }
    if (search && refresh == 'refresh') {
      param.forceRefresh = true
      let time = []
      if (req.injectedArguments.timeRange) {
        time = req.injectedArguments.timeRange
      }
      if (temp == 'Template') {
        const components = p.parameter.components
        const tm = components.find(x => {
          return x.type == 'TimePicker'
        })
        if (tm) {
          time = req.timeRange
        }
      }
      if (time && time.length > 0) {
        param.timeRanges = time
      }
      if (req.injectedArguments.contrastType) {
        param.contrastType = req.injectedArguments.contrastType
      }
    }
    if (search && refresh != 'refresh') {
      param.contrastType = req.injectedArguments.contrastType
      if (p.contrastType != param.contrastType && param.contrastType != 'Current' && p.settings.type === 'table') {
        // p.settings.styleSet.comparisonValue = false
        p.settings.styleSet.enableComparison = true
      }
      if (req.injectedArguments.timeRange) {
        param.timeRanges = req.injectedArguments.timeRange
      }
      if (param.timeRanges && param.contrastType != 'Contrast') {
        param.timeRanges = [param.timeRanges[0]]
      }
      if (req.injectedArguments.selectedGroups) {
        param.groups = p.parameter.groups
        param.groups.forEach(g => {
          if (g.code == 'time' && g.mode == 'Normal') {
            g.preset = req.injectedArguments.selectedGroups[0].value
            g.ranges = null
          }
        })
      }
    }
    if (search) {
      // 过滤条件
      param.prefilter = req.injectedArguments.prefilter
      p.injectedArguments = req.injectedArguments
    }
    if (curbook.linkages) {
      let linkfilter = []
      curbook.linkages.filters.forEach(f => {
        if (f && f.filter) {
          linkfilter = linkfilter.concat(f.filter.conditions)
        }
      })
      if (param.prefilter) {
      } else {
        param.prefilter = {
          operator: 'and',
          conditions: linkfilter,
        }
      }
    }

    param = Object.assign(param)
    let hotmapdata = ['Behavior', 'behavior'].includes(temp) ? await initMarkV2.hotMap(_this, idx, p.parameter) : '1'

    // 如果有钻取
    linkageJS.rtsearch(_this, p, idx, temp, hotmapdata, param)
  },
  rtsearch(_this, p, idx, temp, hotmapdata, param) {
    return Search2(param)
      .then(async d => {
        _this.chartData[idx]['injectsearchparam'] = param
        //2.0 获取数据 直接通过筛选 拿到筛选数据
        // 如果是自定义分析 search 请求状态码是 201 继续请求 直到 200或者 返回错误
        if (temp == 'Advance') {
          let coderes = ''
          if (d && d.code == '201') {
            coderes = await getAdvanceSearch2(d.data.requestID)
            if (coderes.code == '200') {
              linkageDrillJS.searchEnd(_this, p, coderes, idx, temp, hotmapdata)
            }
          } else if (d.code == '200') {
            linkageDrillJS.searchEnd(_this, p, d, idx, temp, hotmapdata)
          }
          if (coderes && coderes.code == '202') {
            let searchRes = ''
            searchRes = await getAdvanceSearch2(d.data.requestID)
            if (searchRes && searchRes.code == '202') {
              let searchtimes = 1
              linkageDrillJS.recurrence(_this, searchRes, d, p, idx, temp, hotmapdata, searchtimes)
            } else if (searchRes.code == '200') {
              d = searchRes
              linkageDrillJS.searchEnd(_this, p, d, idx, temp, hotmapdata)
            }
          } else if (coderes && coderes.code == '200') {
            d = coderes
            linkageDrillJS.searchEnd(_this, p, d, idx, temp, hotmapdata)
          }
        } else {
          linkageDrillJS.searchEnd(_this, p, d, idx, temp, hotmapdata)
        }
      })
      .catch(err => {
        console.error(err)
        if (_this.chartData[idx]) _this.chartData[idx].loading = false
      })
  },
}

export default linkageJS
