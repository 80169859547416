import { Search2, getMallMap, getAdvanceSearch2, getTemplateByCode, getCityWeather } from '@/api/apiV2_dashboard'
import bi_echarts from 'mallcoo_bi_echarts'
const Table = bi_echarts.Table
import { allColor } from '@/datajs/static.js'
import commonJs from '@/script/common.js'
import commonJs2 from '@/script/common2.js'
import linkageDrillJS from '@/views/dashboard/js/linkagedrill'
import store from '@/store'
import BI_config from '@/utils/config'

let initMarkV2 = {
  allColor: allColor,
  init(_this, BI_config, v, el, i) {
    let definition = JSON.parse(el.definition)
    let defcode = el.category
    let themeSet = {}
    let reqObj = JSON.parse(JSON.stringify(definition))
    reqObj.content = el.content
    reqObj['setting'] = reqObj.settings
    reqObj.title = reqObj.settings.nameCN
    reqObj.mode = reqObj.settings.type
    themeSet = reqObj.setting.themeSet
    reqObj.contrastType = reqObj.parameter.contrastType == 'Current' ? 'nocontrast' : reqObj.parameter.contrastType
    let timeRange = reqObj.parameter.timeRanges
    if (el.category == 'template') {
      let timeComp = definition.parameter.components.find(x => {
        return x.type == 'TimePicker'
      })
      timeRange = timeComp ? [timeComp.timeRange] : null
    }
    //高级分析是否有时间
    const hastime_advance = el.category == 'advance' ? getadvancetime(reqObj.parameter) : true

    if (reqObj.contrastType == 'lastCircle') {
      reqObj.contrastType = 'circle'
    }
    reqObj.contrastType = reqObj.contrastType ? reqObj.contrastType.toLowerCase() : ''

    reqObj.injectedArguments = {
      conditionGroup: {
        conditions: [],
        operator: 'and',
      },
    }

    //得到网格数据  //PositionV2
    //若没有 positionV2 获取position
    let posv2 = el.positionV2
    if (!posv2) {
      posv2 = el.position
    }
    let pos = {
      id: el.id,
      x: posv2.col || posv2.col == 0 ? posv2.col : 4,
      y: posv2.row || posv2.row == 0 ? posv2.row : 4,
      w: posv2.x || posv2.x == 0 ? posv2.x : 4,
      h: posv2.y || posv2.y == 0 ? posv2.y : 4,
      i: i,
    }
    _this.position[i] = pos
    // 卡片主题设置 chartMainColor 为 0 跟随系统(_this.basicInfo.project.mainColor) 否则（themeSet.chartMainColor）
    // 项目主题mainColor 0

    const chartMainColor = (themeSet && parseInt(themeSet.chartMainColor)) || 0
    const categoryColor = (themeSet && parseInt(themeSet.categoryColor)) || 0
    let mainColoridx = chartMainColor > 0 ? chartMainColor : _this.basicInfo.project.mainColor
    let mainColor = chartMainColor > 0 ? allColor['color' + (mainColoridx - 1)] : allColor['color' + mainColoridx]

    let url = ''
    let path = el.category + '/' + store.state.projectID + '/' + el.id
    url = BI_config.analysisUrl + BI_config.analysisProxy + '/' + path

    // 是否支持钻取
    const issupportdrill = linkageDrillJS.issupportdrill(el)
    // 是否支持联动
    const issupportlinkage = linkageDrillJS.issupportlinkage(el)
    let chart = {
      index: i,
      id: el.id,
      lock: el.lock,
      canVisit: el.canVisit,
      mapkey: i + '-',
      code: el.code,
      searchCode: defcode,
      reqObj: reqObj,
      timeRange: timeRange && hastime_advance ? JSON.parse(JSON.stringify(timeRange)) : null,
      loading: false,
      url: url,
      option: '',
      otherset: {},
      version: el.version,
      rdMainColor: chartMainColor,
      chartColorV2: mainColor[categoryColor],
      rdChartColor: categoryColor,
      issupportdrill,
      issupportlinkage,
    }
    this.getTimePickerIntervals(el).then(r => {
      chart.timePickerIntervals = r
    })
    let getslot = []
    let slots = []
    if (reqObj.parameter.groups) {
      getslot = reqObj.parameter.groups.filter((g, slotidx) => {
        return g.code == 'time' && g.mode == 'Normal'
      })
      slots = getslot.length > 0 ? getslot[0].preset.split('_') : ''
    }
    chart.otherset.slot = getslot.length > 0 ? (slots.length > 1 ? slots[1] : slots[0]) : null

    return chart
  },
  searchRequest2: (_this, index, scheduler) => {
    let definition = JSON.parse(_this.bookmarks[index].definition)
    let p = definition
    let bookmark = _this.bookmarks[index]
    // 根据获取到的数据组装成 echarts 需要的数据
    let temp = bookmark.category.slice(0, 1).toUpperCase() + bookmark.category.slice(1)
    _this.copyChartData[index]['query'] = p
    _this.copyChartData[index]['bookmarkid'] = _this.bookmarks[index].id
    _this.chartData[index].loading = true
    _this.copyChartData[index]['temp'] = temp
    if (scheduler) {
      scheduler.add(() => {
        return initMarkV2.getSearch2(_this, index, temp, p)
      })
    } else {
      initMarkV2.getSearch2(_this, index, temp, p)
    }
  },
  async drillloop(_this, index) {
    const el = _this.bookmarks[index]
    if (el && el.drillSetting) {
      if (el.drillBookmarks && el.drillSetting.bookmarkIDs.length == el.drillBookmarks.length) {
        return true
      } else {
        setTimeout(async () => {
          return await initMarkV2.drillloop(_this, index)
        }, 100)
      }
    } else {
      return true
    }
  },
  async getSearch2(_this, idx, temp, p, search, req, refresh, realtime) {
    let bookmark = _this.bookmarks[idx]
    let bookmarkid = bookmark && bookmark.id
    const chart = _this.chartData[idx]
    if (chart.prefilter && chart.prefilter.length > 0) {
      bookmarkid = bookmark.drillBookmarks[chart.prefilter.length - 1].id
    }
    let param = {
      id: bookmarkid,
    }
    if (search && refresh == 'refresh') {
      if (!realtime) {
        param.forceRefresh = true
      }
      let time = []
      if (req.injectedArguments.timeRange) {
        time = req.injectedArguments.timeRange
      }
      if (temp == 'Template') {
        const components = p.parameter.components
        const tm = components.find(x => {
          return x.type == 'TimePicker'
        })
        if (tm) {
          time = req.timeRange
        }
      }
      if (time && time.length > 0) {
        param.timeRanges = time
      }
      if (req.injectedArguments.contrastType) {
        param.contrastType = req.injectedArguments.contrastType
      }
    }
    if (search && refresh != 'refresh') {
      param.contrastType = req.injectedArguments.contrastType
      if (p.contrastType != param.contrastType && param.contrastType != 'Current' && p.settings.type === 'table') {
        // p.settings.styleSet.comparisonValue = false
        p.settings.styleSet.enableComparison = true
      }
      if (req.injectedArguments.timeRange) {
        param.timeRanges = req.injectedArguments.timeRange
      }
      if (param.timeRanges && param.contrastType != 'Contrast') {
        param.timeRanges = [param.timeRanges[0]]
      }
      if (req.injectedArguments.selectedGroups) {
        param.groups = p.parameter.groups
        param.groups.forEach(g => {
          if (g.code == 'time' && g.mode == 'Normal') {
            g.preset = req.injectedArguments.selectedGroups[0].value
            g.ranges = null
          }
        })
      }
    }
    if (search) {
      // 过滤条件
      param.prefilter = req.injectedArguments.prefilter
      p.injectedArguments = req.injectedArguments
    }

    param = Object.assign(param)
    let hotmapdata = ['Behavior', 'behavior'].includes(temp) ? await initMarkV2.hotMap(_this, idx, p.parameter) : '1'

    // 如果有钻取
    // function drillloop(_this, index) {
    //   const el = _this.bookmarks[index]
    //   if (el.drillSetting) {
    //     if (el.drillBookmarks && el.drillSetting.bookmarkIDs.length == el.drillBookmarks.length) {
    //       const drillParam = commonJs2.initDrillParam(param, el)
    //       initMarkV2.rtsearch(_this, p, idx, temp, hotmapdata, drillParam)
    //     } else {
    //       setTimeout(async () => {
    //         return drillloop(_this, index)
    //       }, 100)
    //     }
    //   }
    //   initMarkV2.rtsearch(_this, p, idx, temp, hotmapdata, param)
    // }
    // drillloop(_this, idx)
    return initMarkV2.rtsearch(_this, p, idx, temp, hotmapdata, param, refresh)
  },
  rtsearch(_this, p, idx, temp, hotmapdata, param, refresh) {
    return Search2(param)
      .then(async d => {
        if (!d) {
          if (_this.chartData[idx]) _this.chartData[idx].loading = false
          return
        }
        _this.chartData[idx]['injectsearchparam'] = param
        //2.0 获取数据 直接通过筛选 拿到筛选数据
        // 如果是自定义分析 search 请求状态码是 201 继续请求 直到 200或者 返回错误
        if (temp == 'Advance') {
          let coderes = ''
          if (d && d.code == '201') {
            coderes = await getAdvanceSearch2(d.data.requestID)
            if (coderes.code == '200') {
              initMarkV2.searchEnd(_this, p, coderes, idx, temp, hotmapdata, refresh)
            }
          } else if (d.code == '200') {
            initMarkV2.searchEnd(_this, p, d, idx, temp, hotmapdata, refresh)
          }
          if (coderes && coderes.code == '202') {
            let searchRes = ''
            searchRes = await getAdvanceSearch2(d.data.requestID)
            if (searchRes && searchRes.code == '202') {
              let searchtimes = 1
              initMarkV2.recurrence(_this, searchRes, d, p, idx, temp, hotmapdata, searchtimes)
            } else if (searchRes.code == '200') {
              d = searchRes
              initMarkV2.searchEnd(_this, p, d, idx, temp, hotmapdata, refresh)
            }
          } else if (coderes && coderes.code == '200') {
            d = coderes
            initMarkV2.searchEnd(_this, p, d, idx, temp, hotmapdata, refresh)
          }
        } else {
          initMarkV2.searchEnd(_this, p, d, idx, temp, hotmapdata, refresh)
        }
      })
      .catch(err => {
        console.error(err)
        if (_this.chartData[idx]) _this.chartData[idx].loading = false
      })
  },
  async recurrence(_this, searchRes, d, p, idx, temp, hotmapdata, searchtimes) {
    if (searchtimes >= 90) {
      _this.$alert(`查询超时`, '提示', {
        confirmButtonText: '确定',
      })
      return
    }
    try {
      searchRes = await getAdvanceSearch2(d.data.requestID)
    } catch (e) {
      if (_this.chartData[idx]) _this.chartData[idx].loading = false
    }
    if (searchRes && searchRes.code == '200') {
      d = searchRes
      initMarkV2.searchEnd(_this, p, d, idx, temp, hotmapdata)
    } else if (searchRes && searchRes.code == '202') {
      setTimeout(() => {
        searchtimes++
        return initMarkV2.recurrence(_this, searchRes, d, p, idx, temp, hotmapdata, searchtimes)
      }, 2000)
    }
  },
  searchEnd(_this, p, d, idx, temp, hotmapdata, refresh) {
    if (!d.data) return
    let actualLength = d.data.rows.length
    d.data.rows = d.data.rows.slice(0, 10000)

    // 如果是移动端
    let bookmark = _this.bookmarks[idx]
    let mobileBookmarks = _this.dashboard?.mobileSettings?.bookmarks ?? []
    const findMbookmark = mobileBookmarks.find(x => x.id === bookmark.id)
    let bookmarksettings = p.settings
    if (_this.chartData[idx].ismobile) {
      bookmarksettings = findMbookmark && findMbookmark.settings && JSON.parse(findMbookmark.settings) ? JSON.parse(findMbookmark.settings) : p.settings
    }

    // 如果没有名称设置 增加默认名称设置
    // 兼容早期卡片 没有 propSet ，从原始 columns 和 filterset 获取 分组和指标
    const propsets = bookmarksettings.propSet
    if (!propsets && d.data.columns.length > 0 && bookmarksettings.type !== 'table_more' && bookmarksettings.type !== 'funnel') {
      const groupColumns = d.data.columns.filter(x => x.usage === 'Group')
      const respropColumns = d.data.columns.filter(x => x.usage === 'Measure')
      if (!bookmarksettings.filterSet.columns) {
        bookmarksettings.filterSet.columns = []
      }
      const filterprops = bookmarksettings.filterSet.columns.filter(x => !x.usage || x.usage === 'Measure')
      const groups = groupColumns.map(x => {
        return {
          alias: x.alias,
          fullAlias: x.fullAlias,
          title: x.title,
        }
      })
      const props = filterprops.map(x => {
        const targ = respropColumns.find(t => t.fullAlias == x.fullAlias)
        const format = {
          thousands: true, // 千分位
          percent: targ && targ.displayType === 'PERCENTAGE', // 百分比
          digit: null, // 小数点位数,null 不做处理，0，1，2，3 是保留几位小数
        }
        return {
          alias: x.alias,
          fullAlias: x.fullAlias,
          title: x.name,
          format,
        }
      })

      const propsets = {
        groups,
        props,
      }
      bookmarksettings.propSet = propsets
    }
    // 如果图表没有Y轴样式设置 增加默认设置
    if (['line', 'bar', 'bar_line', 'scatter', 'bubble', 'biaxial_graph'].includes(bookmarksettings.type) && !bookmarksettings.styleSet.yAxisformat) {
      bookmarksettings.styleSet.yAxisNumUnit = 'none'
      bookmarksettings.styleSet.yAxisformat = { thousands: true, percent: false, digit: null }
    }
    if (temp === 'Template' && p.parameter.usage === 'Path' && bookmarksettings === 'table') {
      bookmarksettings.type = 'table_path'
    }
    _this.chartData[idx]['settings'] = bookmarksettings

    let result = initMarkV2.getFilterData2(d.data, _this.chartData[idx])
    result.actualLength = actualLength
    if (_this.chartData[idx]) {
      let option = null
      if (result.columns.length == 0 || result.rows.length == 0) {
        option = { noData: true }
      } else {
        let opts = {
          ...bookmarksettings,
          analysisType: temp,
        }
        let tableObj = new Table({
          opts,
          columns: result.columns,
          result: result.rows,
        })
        //Table 内部修改了rows 需要同步更新d1
        result.rows = tableObj.params.result
        const tableObjdata = tableObj.init()
        option = {}
        option.noData = false
        _this.copyChartData[idx]['data'] = result
        _this.chartData[idx]['data'] = result
        _this.chartData[idx]['orginResult'] = result
        _this.copyChartData[idx]['mode'] = opts.type
        _this.copyChartData[idx]['styleset'] = p
        _this.copyChartData[idx]['tableObj'] = tableObj
        _this.copyChartData[idx]['inittableObj'] = tableObjdata
        _this.copyChartData[idx]['orginResult'] = result
        _this.chartData[idx]['tableObj'] = tableObj
        _this.chartData[idx]['inittableObj'] = tableObjdata
        let excelsObj = initMarkV2.getExcelData(tableObjdata)
        _this.copyChartData[idx]['excelsObj'] = excelsObj
        _this.chartData[idx]['excelsObj'] = excelsObj
        _this.chartData[idx]['resizedidx'] = 0

        _this.chartData[idx]['hotmapdata'] = hotmapdata

        // 如果没有名称设置 增加默认名称设置
        // 兼容早期卡片 没有 propSet ，从原始 columns 和 filterset 获取 分组和指标
        const propsets = p.settings.propSet
        if (!propsets && result.columns.length > 0 && p.settings.type !== 'table_more' && p.settings.type !== 'funnel') {
          const groupColumns = result.columns.filter(x => x.usage === 'Group')
          const respropColumns = result.columns.filter(x => x.usage === 'Measure')
          if (!p.settings.filterSet.columns) {
            p.settings.filterSet.columns = []
          }
          const filterprops = p.settings.filterSet.columns.filter(x => !x.usage || x.usage === 'Measure')
          const groups = groupColumns.map(x => {
            return {
              alias: x.alias,
              fullAlias: x.fullAlias,
              title: x.title,
            }
          })
          const props = filterprops.map(x => {
            const targ = respropColumns.find(t => t.fullAlias == x.fullAlias)
            const displayType = (targ && (targ.displayType || targ.dataType)) || ''
            const format = {
              thousands: true, // 千分位
              percent: targ && ['PERCENTAGE', 'PERCENTAGE_DIRECT', 'PERCENTAGE_INTERGER'].includes(displayType), // 百分比
              // 小数点位数,null 不做处理，0，1，2，3 是保留几位小数
              digit: ['PERCENTAGE', 'PERCENTAGE_DIRECT'].includes(displayType) ? 2 : displayType == 'PERCENTAGE_INTERGER' ? 0 : null,
            }
            return {
              alias: x.alias,
              fullAlias: x.fullAlias,
              title: x.name,
              format,
            }
          })

          const propsets = {
            groups,
            props,
          }
          p.settings.propSet = propsets
        }
        // 如果图表没有Y轴样式设置 增加默认设置
        if (['line', 'bar', 'bar_line', 'scatter', 'bubble', 'biaxial_graph'].includes(p.settings.type) && !p.settings.styleSet.yAxisformat) {
          p.settings.styleSet.yAxisNumUnit = 'none'
          p.settings.styleSet.yAxisformat = { thousands: true, percent: false, digit: null }
        }
        if (temp === 'Template' && p.parameter.usage === 'Path' && p.settings === 'table') {
          p.settings.type = 'table_path'
        }
        p.settings = commonJs.compatibleHistory(p.settings)
        _this.chartData[idx]['settings'] = p.settings
        let cardinfos = {
          index: idx,
          cardkey: String(idx),
          cardresizekey: String(idx),
          parameter: p.parameter,
          injectedArguments: p.injectedArguments,
          settings: bookmarksettings,
          result,
          hotmapdata,
          projectopts: {
            basicInfo: _this.basicInfo,
            theme: _this.basicInfo.project.theme,
            mainColor: _this.basicInfo.project.mainColor,
            pageunit: _this.basicInfo.project.axisUnit,
            contrastTrendColor: _this.basicInfo.project.contrastTrendColor,
            projectMalls: _this.projectMalls,
            weathers: store.state.weathers,
            festivals: store.state.festivals,
          },
          cardopts: {
            searchCode: _this.chartData[idx].searchCode,
            category: temp,
            enableRealtimeRefresh: bookmark.enableRealtimeRefresh,
            refresh,
          },
          // drillFilter, //钻取筛选条件
        }
        if (temp.toLowerCase() == 'segment') {
          cardinfos.cardopts.uniqueID = result.uniqueID
          cardinfos.cardopts.segmentName = bookmarksettings.nameCN || bookmarksettings.nameEN
        }

        if (_this.ismobile) {
          const mobilebookmarks = _this.dashboard?.mobileSettings?.bookmarks ?? []
          let findsettings = ''
          if (mobilebookmarks && mobilebookmarks.length > 0) {
            findsettings = mobilebookmarks.find(mbook => _this.chartData[idx].id === mbook.id)
          }
          if (findsettings && findsettings.settings) {
            cardinfos.settings = JSON.parse(findsettings.settings)
          }
        }

        _this.$set(_this.chartData[idx], 'cardinfos', cardinfos)

        // if (opts.type === 'card' && option && option.arr.length > 0) {
        //   _this.$set(_this.chartData[idx], 'curcard', option.arr[0])
        //   _this.$set(_this.chartData[idx], 'curcardlabel', option.arr[0].label)
        // }
      }
      _this.resetChartOption(idx, option)
      _this.chartData[idx].loading = false
    }
  },
  getExcelData: table => {
    let excels = {
      tbdata: [],
      fields: {},
    }
    excels.tbdata = table.tbody
    table.thead.forEach(x => {
      // excels.fields[x.label] = x.prop || x.children[0].prop
      if (['对比值', '对比率', '同比值', '同比率', '环比值', '环比率'].includes(x.label)) {
        let curevent = table.thead.find(k => {
          return x.prop.includes(k.prop)
        })
        excels.fields[curevent.label + '-' + x.label] = x.prop || x.children[0].prop
      } else {
        excels.fields[x.label] = x.prop || x.children[0].prop
      }
      if (x.prop == 'event_time_dim' || x.prop == 'day_dim') {
        excels.fields[x.label] = {
          //判断是否为日期年月的情况 年月格式excel没有 2020-01 的格式会自动转为英文格式 改为 2020年01月
          callback: value => {
            let times = value[x.prop].split('-')
            let time = ''
            time = times.length == 2 ? times[0] + '年' + times[1] + '月' : value[x.prop]
            return time
          },
        }
      }
    })
    return excels
  },
  getFilterData2: (d, chart) => {
    let copyres = _.cloneDeep(d)
    let filterSet = (chart.settings && chart.settings.settings) || chart.reqObj.settings.filterSet
    const propsetGroup = chart.settings?.propSet?.groups ?? []
    // let funnelarr = ['funnel', 'table_more']
    // if (funnelarr.includes(chart.reqObj.mode)) {
    //   return d
    // }

    let groups = copyres.columns.filter(x => {
      return x.usage == 'Group'
    })
    let measures = copyres.columns.filter(x => {
      return x.usage == 'Measure'
    })
    let rows = copyres.rows.filter(x => {
      let s = ''
      let isUnknown = false
      groups.forEach((g, gidx) => {
        //隐藏未知
        if (filterSet && filterSet.hideUnknown) {
          if (x[g.alias] == '-') {
            isUnknown = true
          }
        }
        if (gidx < groups.length - 1 && groups.length > 1) {
          s += x[g.alias] + '[|]'
        } else {
          s += x[g.alias]
        }
      })
      x.sys_gen_key = s
      // 指标类型 PERCENTAGE_DIRECT 的值 *0.01
      if (!x.per_direct) {
        copyres.columns.forEach(m => {
          if (m.displayType == 'PERCENTAGE_DIRECT') {
            x[m.alias] = x[m.alias] * 0.01
          }
        })
        x.per_direct = true
      }
      return x && !isUnknown
    })
    let filterdata = copyres

    if (copyres.columns.length > 0) {
      let oldname = ''
      filterdata.columns = copyres.columns.filter(x => {
        if (x.usage === 'Group') {
          const grp = propsetGroup.find(g => g.fullAlias === x.fullAlias)
          if (grp) x.title = grp.title
        }
        let targ =
          filterSet.columns &&
          filterSet.columns.find(t => {
            if (t.fullAlias == x.fullAlias) {
              oldname = x.title
            }
            return t.fullAlias == x.fullAlias
          })
        let isshow = targ && (!targ.usage || targ.usage === 'Measure') ? targ.selected : true
        x.title = targ && (!targ.usage || targ.usage === 'Measure') ? targ.name : initMarkV2.reviseName(copyres, x)
        if (!targ) {
          let meatarg =
            filterSet &&
            filterSet.columns &&
            filterSet.columns.find(t => {
              return x.alias.includes(t.alias)
            })
          if (meatarg) {
            x.title = x.title.replace(oldname, meatarg.name)
          }
        }
        return isshow
      })

      //指标数值过滤开启时，根据指标start，end过滤数值
      if (filterSet.filterValue) {
        rows = rows.filter(x => {
          let istrue = true
          filterSet.columns &&
            filterSet.columns.forEach(c => {
              let val =
                c.filterCode && x[c.filterCode]
                  ? typeof x[c.filterCode] === 'number'
                    ? x[c.filterCode]
                    : x[c.filterCode] == '-'
                    ? '-'
                    : parseFloat(x[c.filterCode].replace(/[,]/g, ''))
                  : x[c.alias]
              if ((c.start || c.start === 0) && (c.end || c.end === 0) && c.start <= c.end && val >= c.start && val <= c.end) {
              } else if ((c.start || c.start === 0) && (c.end || c.end === 0) && c.start > c.end && val >= c.start) {
              } else if (!c.start && c.start !== 0 && (c.end || c.end === 0) && val <= c.end) {
              } else if ((c.start || c.start === 0) && !c.end && c.end !== 0 && val >= c.start) {
              } else if (!c.start && c.start !== 0 && !c.end && c.end !== 0) {
              } else {
                istrue = false
              }
            })
          return istrue
        })
        filterdata.rows = rows
      } else {
        filterdata.rows = rows
      }

      // 漏斗
      if (filterdata.funnels) {
        let funnels = filterdata.funnels.map(x => {
          let itemres = initMarkV2.getFunnelSteps(x, chart.settings)
          return itemres
        })
        filterdata.funnels = funnels
      }
    } else {
      filterdata = copyres
      filterdata.rows = rows
    }
    if (copyres.timeRanges) {
      filterdata.timeRanges = copyres.timeRanges
    }
    if (copyres.uniqueID) {
      filterdata.uniqueID = copyres.uniqueID
    }
    // 如果分组名设置了不可见
    const groupformatlist = propsetGroup.filter(x => x.groupformat && x.groupformat.length > 0)
    if (groupformatlist) {
      filterdata.rows.forEach(x => {
        groupformatlist.forEach(g => {
          g.groupformat.forEach(f => {
            x[g.alias] = x[g.alias].replace(f, '')
          })
        })
      })
    }

    return filterdata
  },
  getFunnelSteps(searchRes, settings) {
    const copyColumns = JSON.parse(JSON.stringify(searchRes.columns))
    let res = searchRes
    const propsetGroup = settings?.propSet?.groups ?? []

    let columns = copyColumns.map(x => {
      if (x.usage === 'Group') {
        const grp = propsetGroup.find(g => g.fullAlias === x.fullAlias)
        if (grp) x.title = grp.title
      }
      return x
    })
    res.columns = columns
    return res
  },
  reviseName(d, column) {
    let name = column.title
    //是否同环比值
    d.columns.forEach(x => {
      if (x.alias + '_contrast' == column.alias) {
        name = '对比' + x.title
      }
      if (x.alias + '_last' == column.alias) {
        name = '去年同期' + x.title
      }
      if (x.alias + '_circle' == column.alias) {
        name = '上一周期' + x.title
      }
    })
    return name
  },
  searchchangeV2(_this, idx, req, contrastitem, type, scheduler, realtime) {
    let bookmark = _this.bookmarks[idx]
    let definition = JSON.parse(bookmark.definition)
    let temp = ''
    let p = ''
    let p2 = {}
    // temp = bookmark.category == 'template' ? req.parameter.usage : bookmark.category.slice(0, 1).toUpperCase() + bookmark.category.slice(1)
    temp = bookmark.category.slice(0, 1).toUpperCase() + bookmark.category.slice(1)
    p2 = definition
    _this.chartData[idx].loading = true
    _this.chartData[idx].option = null

    let inject = req.injectedArguments
    if (type == 'contrast' || type == 'Contrast') {
      let contrast = req.contrastType
      let v2item = {}
      v2item.v2time = inject && inject.timeRange ? inject.timeRange : req.parameter.timeRanges
      switch (contrast) {
        case 'nocontrast':
          contrast = 'Current'
          break
        case 'contrast':
          contrast = 'Contrast'
          var timeRa = inject && inject.timeRange ? inject.timeRange : req.parameter.timeRanges
          v2item.v2time = initMarkV2.getV2timeRan(_this, req, timeRa)
          break
        case 'last':
          contrast = 'Last'
          break
        case 'circle':
          contrast = 'Circle'
          break
        case 'lastAndCircle':
          contrast = 'LastAndCircle'
          break
      }
      let timeRan = _this.$commonJs.getContrastTimeV2(_this, req, v2item)
      inject.timeRange = timeRan
      inject.contrastType = contrast
      _this.chartData[idx].timeRange = timeRan
      p2.ContrastType = contrast
    } else if (type == 'slot') {
      inject.timeRange = JSON.parse(JSON.stringify(inject.timeRange || req.parameter.timeRanges))
      let contrast = inject.contrastType ? inject.contrastType : req.contrastType
      switch (contrast) {
        case 'nocontrast':
          contrast = 'Current'
          break
        case 'contrast':
          contrast = 'Contrast'
          break
        case 'last':
          contrast = 'Last'
          break
        case 'circle':
          contrast = 'Circle'
          break
        case 'lastAndCircle':
          contrast = 'LastAndCircle'
          break
      }
      inject.contrastType = contrast
      inject.selectedGroups = [{ code: 'eventTime', type: 'date', category: 3, value: contrastitem, text: '事件发生时间' }]
      p2.ContrastType = contrast
    } else if (type == 'mallid') {
      p.conditionGroup = JSON.parse(JSON.stringify(req.conditionGroup))
      inject.conditionGroup = JSON.parse(JSON.stringify(req.conditionGroup))
      inject.timeRange = JSON.parse(JSON.stringify(inject.timeRange || req.parameter.timeRanges))
    } else if (type == 'refresh') {
      if (inject.timeRange || req.parameter.timeRanges) {
        inject.timeRange = JSON.parse(JSON.stringify(inject.timeRange || req.parameter.timeRanges))
      }
    }
    if (inject && inject.timeRange) {
      req.timeRange = inject.timeRange
    }
    _this.$set(_this.chartData[idx], 'reqObj', req)
    if (inject.timeRange) {
      _this.$set(_this.chartData[idx], 'timeRange', inject.timeRange)
    }

    if (scheduler) {
      console.log(idx)
      scheduler.add(() => {
        return initMarkV2.getSearch2(_this, idx, temp, p2, 'search', req, type, realtime)
      })
    } else {
      initMarkV2.getSearch2(_this, idx, temp, p2, 'search', req, type, realtime)
    }
  },
  getV2timeRan(_this, req, timeRange) {
    let time = timeRange
    let injectedArguments = req.injectedArguments
    if (injectedArguments.timeRange) {
      time = injectedArguments.timeRange
    }
    let t1 = JSON.parse(JSON.stringify(time[0]))
    let t2 = {}
    if (t1.mode == 'Relative') {
      let showtime = commonJs.getshowdata(_this, t1)
      t2.start = showtime[0]
      t2.end = showtime[1]
      t2.mode = 'Absolute'
    } else {
      t2 = t1
    }
    const diff_days = _this.moment(t2.end).diff(_this.moment(t2.start), 'days')
    t2.end = _this
      .moment(t2.start)
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
    const date2 = _this
      .moment(t2.start)
      .subtract(diff_days + 1, 'days')
      .format('YYYY-MM-DD')
    t2.start = date2
    if (!time[1]) {
      time[1] = t2
    }
    return time
  },
  async hotMap(_this, idx, searchParam) {
    let mapData = ''
    let event = searchParam.events
    let group = searchParam.groups
    let filter = searchParam.filter
    if (group && group.length == 2 && filter && filter.conditions.length == 2) {
      let conditions = filter.conditions
      let mall = filter.conditions.filter(x => {
        return x.code == '_mall_id' && x.operator == '=' && x.values.length == 1
      })[0]
      let floor = filter.conditions.filter(x => {
        return x.code == '_floor_id' && x.operator == '=' && x.values.length == 1
      })[0]
      if (
        event.length == 1 &&
        group.length == 2 &&
        ['_shop_name', '_shop_id'].includes(group[0].code) &&
        ['_shop_name', '_shop_id'].includes(group[1].code) &&
        conditions.length == 2 &&
        filter.operator == 'and' &&
        mall &&
        floor
      ) {
        let p = {
          mallID: mall.values[0],
          floorID: floor.values[0],
        }
        await getMallMap(p).then(v => {
          mapData = v.data
        })
      } else {
        mapData = 1
      }
    } else {
      mapData = 1
    }
    return mapData
  },
  async getTimePickerIntervals(el) {
    let timePickerIntervals = []
    if (el.category == 'template') {
      let templateCode = JSON.parse(el.definition).parameter.code
      let template = (await getTemplateByCode(templateCode)).data
      let tComponents = template.definition.components
      var bComponents = JSON.parse(el.definition).parameter.components
      bComponents
        .filter(x => x.type == 'TimePicker')
        .forEach(x => {
          let timeComponent = tComponents.find(y => y.code == x.code)
          if (timeComponent) {
            timePickerIntervals.push(timeComponent.settings.interval)
          } else {
            timePickerIntervals.push('-1')
          }
        })
    }
    return timePickerIntervals
  },
}

function getadvancetime(parameter) {
  let hastime = false
  parameter.analyses.forEach(x => {
    if (!hastime && x.category === 'behavior') {
      hastime = true
    }
    if (!hastime && x.category === 'template') {
      const components = JSON.parse(x.definition).parameter.components
      const timeRanges = components.find(c => {
        return c.type === 'TimePicker'
      })
      if (timeRanges) hastime = true
    }
  })

  return hastime
}

export default initMarkV2
